import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { authentication } from './authentication/reducers';
import { global } from './global/reducers';
import { notifications } from './notifications/reducers';
import { games } from './games/reducers';
import { clients } from './clients/reducers';
import { campaigns } from './campaigns/reducers';
import { prizes } from './prizes/reducers';
import { questions } from './questions/reducers';
import { users } from './users/reducers';
import { files } from './files/reducers';
import { reports } from './reports/reducers';

const appReducer = combineReducers({
  global,
  authentication,
  notifications,
  games,
  clients,
  campaigns,
  prizes,
  questions,
  users,
  files,
  reports,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

/* eslint-disable no-underscore-dangle */
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer /* preloadedState, */,
  composeEnhancer(applyMiddleware(thunkMiddleware))
);
/* eslint-enable */

export default store;
