import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import './GameSetup.scss';
import GameSetupForm from './GameSetupForm';
import Select from 'react-select';
import { selectStyles } from 'components/selectStyles';
import { fetchPrizes, updatePrizes } from 'store/prizes/actions';
import { createCampaign, updateCampaign } from 'store/campaigns/actions';
import { fetchAvailableCampaignsForClient } from 'store/campaigns/actions';
import { useHistory, useParams } from 'react-router-dom';

const GameSetup = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId, campaignId } = useParams();

  const [selectedCampaign, selectCampaign] = useState('');
  const [initialValues, setInitialValues] = useState(null);

  const {
    availableCampaigns,
    prizesByCampaign,
    campaignTypes,
    client,
    clientId,
  } = useSelector((state) => ({
    availableCampaigns: state.campaigns.available,
    prizesByCampaign: state.prizes.byCampaign,
    campaignTypes: state.campaigns.types,
    client: state.clients.client,
    clientId: state.authentication.user.clientId,
  }));

  // console.log(selectedCampaign)

  const selectCampaignHandler = useCallback(
    (value) => {
      selectCampaign(value);
      history.push(
        value?.id
          ? `/user/${userId}/game-setup/${value.id}`
          : `/user/${userId}/game-setup`
      );
    },
    [history, userId]
  );

  const newCampaign = {
    id: null,
    campaignType: {},
    campaignTypeId: null,
    startDate: '',
    endDate: '',
    prizes: [],
    questions: [],
    users: [],
    label: 'New Campaign',
  };
  useEffect(() => {
    if (campaignId && !selectedCampaign && availableCampaigns?.length > 0) {
      selectCampaign(availableCampaigns.find(({ id }) => id === campaignId));
    }
  }, [selectedCampaign, campaignId, availableCampaigns]);

  useEffect(() => {
    if (selectedCampaign?.id) {
      dispatch(fetchPrizes(selectedCampaign.id));
      // const initialValues = {
      //   ...selectedCampaign,
      //   wheelPrizes: selectedCampaign.prizes.filter(
      //     (e) => !e.isPressYourLuckPrize
      //   ),
      //   pressYourLuckPrizes: {
      //     green: selectedCampaign.prizes.find(
      //       (e) => e.isPressYourLuckPrize && e.rank === 1
      //     ),
      //     yellow: selectedCampaign.prizes.find(
      //       (e) => e.isPressYourLuckPrize && e.rank === 2
      //     ),
      //     orange: selectedCampaign.prizes.find(
      //       (e) => e.isPressYourLuckPrize && e.rank === 3
      //     ),
      //     logo: selectedCampaign.prizes.find(
      //       (e) => e.isPressYourLuckPrize && !e.rank
      //     ),
      //   },
      // };

      // setInitialValues(initialValues);
    }
  }, [dispatch, selectedCampaign]);

  useEffect(() => {
    if ((selectedCampaign, prizesByCampaign)) {
      const initialValues = {
        ...selectedCampaign,
        wheelPrizes: prizesByCampaign.filter((e) => !e.isPressYourLuckPrize),
        pressYourLuckPrizes: {
          green: prizesByCampaign.find(
            (e) => e.isPressYourLuckPrize && e.rank === 1
          ),
          yellow: prizesByCampaign.find(
            (e) => e.isPressYourLuckPrize && e.rank === 2
          ),
          orange: prizesByCampaign.find(
            (e) => e.isPressYourLuckPrize && e.rank === 3
          ),
          logo: prizesByCampaign.find((e) => e.isPressYourLuckPrize && !e.rank),
        },
      };

      setInitialValues(initialValues);
    }
  }, [dispatch, selectedCampaign, prizesByCampaign]);

  const onSubmit = useCallback(
    ({ action, ...values }) => {
      const {
        wheelPrizes,
        pressYourLuckPrizes,
        campaignType,
        id,
        prizes,
        ...campaign
      } = values;

      const handler = id ? updateCampaign : createCampaign;
      let newPrizes = [];

      if (campaignType.isSmartSpinAllowed) {
        newPrizes = newPrizes.concat(wheelPrizes);
      }
      if (campaignType.isPressYourLuckAllowed) {
        for (let key in pressYourLuckPrizes) {
          if (pressYourLuckPrizes[key]) {
            newPrizes.push(pressYourLuckPrizes[key]);
          }
        }
      }

      return dispatch(handler(id ? { ...campaign, id } : campaign, client.id))
        .then(async (response) => {
          if (
            campaignType.isSmartSpinAllowed ||
            campaignType.isPressYourLuckAllowed
          ) {
            await dispatch(updatePrizes(response.id, newPrizes));
          }

          return response;
        })
        .then(async (response) => {
          const campaigns = await dispatch(
            fetchAvailableCampaignsForClient(clientId)
          );

          if (action === 'next') {
            history.push(`/user/${userId}/questions/${response?.id}`);
          } else {
            selectCampaignHandler(
              campaigns.find(({ id }) => id === response?.id)
            );
          }
        });
    },
    [client, dispatch, history, selectCampaignHandler, userId, clientId]
  );

  const onPrev = useCallback(() => {
    return history.push(`/user/${userId}/branding`);
  }, [history, userId]);

  const onNext = useCallback(() => {
    return history.push(`/user/${userId}/questions/${selectedCampaign.id}`);
  }, [selectedCampaign, history, userId]);

  return (
    <div className='steps-content game-setup'>
      <GameSetupForm
        onSubmit={onSubmit}
        newCampaig={newCampaign}
        selectCampaign={() => selectCampaignHandler(newCampaign)}
        selectedCampaign={selectedCampaign}
        campaignTypes={campaignTypes}
        initialValues={initialValues || {}}
        onPrev={onPrev}
        onNext={onNext}>
        <div className='active-campaign-block'>
          <h3>Active Campaign</h3>
          <Select
            placeholder='Select campaign'
            styles={selectStyles}
            value={selectedCampaign}
            isDisabled={!availableCampaigns}
            options={availableCampaigns}
            onChange={selectCampaignHandler}
            getOptionLabel={(option) => option?.campaignName || option.label}
          />
        </div>
      </GameSetupForm>
    </div>
  );
};

export default GameSetup;
