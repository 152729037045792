import React from 'react';
import { useSelector } from 'react-redux';
import './styles.scss';

const Content = ({ children }) => {
  const activeTheme = useSelector((state) => state.global.activeTheme);
  return (
    <div className={`content ${activeTheme ? activeTheme : 'full'}`}>
      {children}
    </div>
  );
};

export default Content;
