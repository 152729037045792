export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export const enqueueSnackbar = notification => {
  const options = notification.options;
  const key = options && options.key;

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      options: options || { variant: "success" },
      key: key || new Date().getTime() + Math.random()
    }
  };
};

export const closeSnackbar = key => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key,
  key
});

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key
});
