import React from 'react';
import logo from './assets/smart-spin-logo.png';
import { Link } from 'react-router-dom';

import './MainLogo.scss';

function MainLogo() {
  return (
    <div className='main-logo'>
      <Link to='/'>
        <img src={logo} alt='Smart Spin Logo' />
      </Link>
    </div>
  );
}

export default MainLogo;
