import React, { PureComponent, Suspense } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Loader from 'components/Loader';
import PrivateRoute from 'components/PrivateRoute';
import NoMatch from 'components/NoMatch';
import Content from './Content';
import PageLoader from './PageLoader';
import Notifier from './Notifier';
import User from 'containers/User';
import Login from 'containers/Login';

class Application extends PureComponent {
  render() {
    const { requestQueue, isAuthenticated, userId } = this.props;

    const userID = userId || sessionStorage.getItem('userId');

    return (
      <>
        <PageLoader requestQueue={requestQueue} />
        <Content>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route exact path={`/`}>
                {isAuthenticated && userID ? (
                  <Redirect to={`/user/${userID}`} />
                ) : (
                  <Redirect to={'/login'} />
                )}
              </Route>

              <Route exact path={`/login`}>
                {isAuthenticated && userID ? (
                  <Redirect to={`/user/${userID}`} />
                ) : (
                  <Login />
                )}
              </Route>

              <PrivateRoute
                path={'/user/:userId/:step?/:campaignId?'}
                isAuthenticated={isAuthenticated}
                isPermitted={true}
                component={User}
              />

              <Route component={NoMatch} />
            </Switch>
          </Suspense>
        </Content>
        <Notifier />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.authentication.isAuthenticated,
  userId: state.authentication.userId,
  requestQueue: state.global.requestQueue,
});

const mapDispatchToProps = {};

Application = connect(mapStateToProps, mapDispatchToProps)(Application);
Application = withRouter(Application);

export default Application;
