import React from 'react';

function Input({
  dataLabel,
  id,
  name,
  type,
  value,
  placeholder,
  handleChange,
  ...props
}) {
  return (
    <div className='form-group'>
      <label>{dataLabel}</label>
      <div className='input'>
        <div className='icon'>{props.icon}</div>
        <input
          className='form-input'
          id={id}
          name={name}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          {...props}
        />
      </div>
    </div>
  );
}

export default Input;
