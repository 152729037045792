import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  fetchUser,
  changePassword,
  toggleResetPassDialog,
} from 'store/authentication/actions';
import ChangePasswordForm from './ChangePasswordForm';

import './ChangePassword.scss';

class ChangePassword extends PureComponent {
  onSubmit = (values) => {
    const {
      changePassword,
      toggleResetPassDialog,
      setPasswordChangeEnforced,
      fetchUser,
      user,
    } = this.props;

    return changePassword(values)
      .then(() => fetchUser(user.id))
      .then(() => {
        setPasswordChangeEnforced(false);
        toggleResetPassDialog(false);
      });
  };

  render() {
    const { toggleResetPassDialog, user } = this.props;

    return (
      <div className='change-password'>
        <div className='inner-content'>
          <h2>Change Password</h2>
          <ChangePasswordForm user={user} onSubmit={this.onSubmit} />
          {!user.isPasswordChangeEnforced && (
            <button
              className='cancel'
              onClick={() => toggleResetPassDialog(false)}>
              Cancel
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  fetchUser,
  changePassword,
  toggleResetPassDialog,
};

ChangePassword = connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
ChangePassword = withRouter(ChangePassword);

export default ChangePassword;
