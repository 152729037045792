import {
  LOGIN,
  LOGOUT,
  FETCH_USER,
  TOGGLE_LOGOUT_DIALOG,
  TOGGLE_RESET_PASS_DIALOG,
} from './actions';

const initialState = {
  userId: null,
  user: null,
  isAuthenticated:
    !!localStorage.getItem('ACCESS_TOKEN') ||
    !!sessionStorage.getItem('ACCESS_TOKEN'),
  quitDialog: false,
  resetPassDialog: false,
};
export const authentication = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN:
      sessionStorage.setItem('userId', payload);
      return {
        ...state,
        isAuthenticated: true,
        userId: payload,
      };

    case LOGOUT:
      sessionStorage.removeItem('userId', payload);
      return {
        ...state,
        isAuthenticated: false,
      };

    case FETCH_USER:
      return {
        ...state,
        user: {
          ...payload,
        },
      };
    case TOGGLE_LOGOUT_DIALOG:
      return {
        ...state,
        quitDialog: payload,
      };

    case TOGGLE_RESET_PASS_DIALOG:
      return {
        ...state,
        resetPassDialog: payload,
      };

    default:
      return state;
  }
};
