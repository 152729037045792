import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { toggleLogoutDialog } from 'store/authentication/actions';
import { useHistory, useParams } from 'react-router-dom';
import WheelOfFortune from './WheelOfFortune';
import PressYourLuck from './PressYourLuck';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import CloseIcon from '@material-ui/icons/Close';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import {
  fetchAvailablePrizes,
  fetchGameStatus,
  updateGameStatus,
} from 'store/games/actions';
import {
  getActiveCanpaign,
  getAvailablePrizes,
  getLogo,
} from 'store/games/selectors';
import './Spin.scss';
import { logout } from 'store/authentication/actions';
import BrandLogo from 'containers/User/BrandLogo';
import { addAwardedPrizeForCampaign } from 'store/prizes/actions';
import useSound from 'use-sound';
import gamesound from './assets/gamesound.mp3';
import mainLogo from 'components/MainLogo/assets/smart-spin-logo.png';

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    left: '31%',
    top: '35%',
    transform: `translate(-50%, -50%)`,
  },
});

let Spin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { campaignId } = useParams();
  const classes = useStyles();
  //const [playing, toggle] = useAudio(gamesound);
  // const [play, { duration, stop }] = useSound(gamesound);
  const [play, { duration, stop }] = useSound(gamesound);
  const {
    user,
    availablePrizes,
    minimumPoints,
    activeCanpaign,
    logo,
    gameStatus,
  } = useSelector(
    (state) => ({
      user: state.authentication.user,
      availablePrizes: getAvailablePrizes(state.games),
      minimumPoints: getAvailablePrizes(state.games)
        ? getAvailablePrizes(state.games)
            .filter((e) => !e.isPressYourLuckPrize)
            .sort((a, b) =>
              a?.pointsNeededMinimum < b?.pointsNeededMinimum ? -1 : 1
            )[0]?.pointsNeededMinimum
        : 0,
      activeCanpaign: getActiveCanpaign(state.games),
      logo: getLogo(state.games),
      gameStatus: state.games.status,
    }),
    shallowEqual
  );

  const [spins, setSpins] = useState(0);
  const [points, setPoints] = useState(0);
  const [optionSound, setOptionSound] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [pressYourLuck, setPressYourLuck] = useState(false);
  const [pressYourLuckModal, setPressYourLuckModal] = useState(false);
  const [loseModal, setLoseModal] = useState(false);
  const [prizeModal, setPrizeModal] = useState(false);
  const [alertedPrize, setAlertedPrize] = useState(null);
  const [awarderPrize, setAwardedPrize] = useState(null);

  window.setPressYourLuckModal = setPressYourLuckModal;

  useEffect(() => {
    let intervalId;
    if (!pressYourLuck && optionSound) {
      intervalId = setInterval(
        (function intPlay() {
          play();
          return intPlay;
        })(),
        duration
      );
    } else {
      intervalId && clearInterval(intervalId);
      stop();
    }
    return () => {
      intervalId && clearInterval(intervalId);
      stop();
    };
  }, [play, stop, duration, pressYourLuck, optionSound]);


  useEffect(() => {
    dispatch(
      fetchGameStatus({
        campaignId: campaignId,
        userId: user.id,
      })
    );
  }, [campaignId, dispatch, user.id]);

  useEffect(() => {
    if (activeCanpaign) {
      dispatch(fetchAvailablePrizes(activeCanpaign.id));
    }
    if (!user) {
      history.push('/login');
    }
  }, [activeCanpaign, dispatch, history, user]);

  useEffect(() => {
    if (gameStatus) {
      // setDisabled(gameStatus.remainingSpins === 0);
      setSpins(gameStatus.remainingSpins);
      setPoints(gameStatus.totalPointsEarned);
    }
  }, [gameStatus, user]);

  const pad = (d) => {
    return d < 10 ? '0' + d.toString() : d.toString();
  };

  const startSpinCallback = () => {
    setSpins(spins > 0 ? spins - 1 : 0);
    setDisabled(true);
  };

  const getPrize = useCallback(
    (points) => {
      const prizes = availablePrizes
        ? [...availablePrizes].sort((a, b) =>
            a.pointsNeededMinimum < b.pointsNeededMinimum ? 1 : -1
          )
        : [];

      return prizes.find((prize) => {
        return (
          prize.pointsNeededMinimum &&
          prize.pointsNeededMinimum <= points &&
          prize.winnerTotal > 0
        );
      });
    },
    [availablePrizes]
  );

  const prizeCallback = useCallback(
    (data) => {
      if (data.value === 'pressYourLuck') {
        setPressYourLuckModal(true);
        const newGameStatus = {
          ...activeCanpaign.gameStatuses.find((e) => e.userId === user.id),
          remainingSpins: spins,
          totalPointsEarned: points,
        };
        dispatch(updateGameStatus({ id: newGameStatus.id }, newGameStatus));
        return;
      } else {
        setPoints(points + data.value);
      }

      const newGameStatus = {
        ...activeCanpaign.gameStatuses.find((e) => e.userId === user.id),
        remainingSpins: spins,
        totalPointsEarned:
          typeof data.value === 'number' ? points + data.value : points,
      };

      if (spins === 0) {
        const prize = getPrize(points + data.value);
        newGameStatus.gameStatusType = 1;
        if (prize) {
          setAlertedPrize(prize);
          setAwardedPrize(prize);
          setPrizeModal(true);
          return dispatch(
            addAwardedPrizeForCampaign({
              userId: user.id,
              prizeId: prize.id,
              campaignId,
            })
          ).then(() => {
            return dispatch(
              updateGameStatus({ id: newGameStatus.id }, newGameStatus)
            ).then((data) => setDisabled(data.remainingSpins === 0));
          });
        } else {
          setLoseModal(true);
        }
      }

      dispatch(updateGameStatus({ id: newGameStatus.id }, newGameStatus)).then(
        (data) => setDisabled(data.remainingSpins === 0)
      );
    },
    [activeCanpaign, campaignId, dispatch, getPrize, points, spins, user.id]
  );

  const iconPrizeHandler = useCallback(
    (prize) => {
      setAlertedPrize(prize);
      setPrizeModal(true);
    },
    [setAlertedPrize, setPrizeModal]
  );

  const handleLogout = useCallback(
    (thankyou = false) => {
      dispatch(logout()).then(() => {
        history.push(thankyou ? '/login?thankyou=true' : '/login');
      });
    },
    [dispatch, history]
  );

  if (!user || !activeCanpaign) return null;

  return (
    <>
      {pressYourLuck && availablePrizes ? (
        <>
          <PressYourLuck
            logo={logo}
            handleLogout={handleLogout}
            availablePrizes={availablePrizes}
          />
        </>
      ) : (
        <div className='spin'>
          <div className='game'>
            <div className='placer'>
              <WheelOfFortune
                bonusPointsAwarded={activeCanpaign?.bonusPointsAwarded}
                isPressYourLuckAllowed={
                  activeCanpaign?.campaignType?.isPressYourLuckAllowed
                }
                startSpinCallback={startSpinCallback}
                prizeCallback={prizeCallback}
                optionSound={optionSound}
                disabled={disabled}
              />
            </div>
          </div>
          <div className='description'>
            <div className='description-header'>
              <div className='name'>{user.fullName}</div>
              <div className='logo'>
                <BrandLogo />
              </div>
            </div>
            <div className='score'>
              <div className='points'>
                <div className='count'>{pad(spins)}</div>
                <div className='type'>SPINS REMAINING</div>
              </div>
              <div className='points'>
                <div className='count'>{pad(points)}</div>
                <div className='type'>POINTS EARNED</div>
              </div>
            </div>
            <div className='prizes'>
              {availablePrizes &&
              availablePrizes.sort((a, b) =>
                a.pointsNeededMaximum > b.pointsNeededMaximum ? 1 : -1
              ) ? (
                <table>
                  <tbody>
                    <tr
                      className={points <= minimumPoints ? 'highlighted' : ''}>
                      <td></td>
                      <td>No Prize</td>
                      <td>
                        {availablePrizes.length > 0 && minimumPoints > 0
                          ? `0-${minimumPoints - 1}`
                          : ''}
                        {}
                      </td>
                    </tr>
                    {availablePrizes
                      .filter((e) => !e.isPressYourLuckPrize)
                      .map((prize) => (
                        <tr
                          key={prize.id}
                          className={
                            points >= prize.pointsNeededMinimum &&
                            points <= prize.pointsNeededMaximum
                              ? 'highlighted'
                              : ''
                          }>
                          <td>
                            <button
                              className='icon-button'
                              onClick={() => iconPrizeHandler(prize)}>
                              <PhotoCameraOutlinedIcon
                                style={{ fontSize: 18, marginTop: 5 }}
                              />
                            </button>
                          </td>
                          <td>{prize.name}</td>
                          <td>
                            {!prize.isPressYourLuckPrize && (
                              <>{`${prize.pointsNeededMinimum} - ${prize.pointsNeededMaximum}`}</>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                <CircularProgress color='secondary' />
              )}
            </div>
            <div className='logo-points'>
              <div className='logo-points-image'>
                <img src={mainLogo} alt='' />
              </div>
              <div className='logo-points-label'>
                Bonus: earn{' '}
                <b>
                  {activeCanpaign?.bonusPointsAwarded
                    ? activeCanpaign?.bonusPointsAwarded
                    : 0}
                </b>{' '}
                points
              </div>
            </div>
            <div className='links'>
              <button
                className='log-off'
                onClick={() => dispatch(toggleLogoutDialog(true))}>
                Quit
              </button>
            </div>
            <button
              className='sound'
              onClick={() => {
                setOptionSound(!optionSound);
              }}>
              <span>SOUND</span>
              <span>{optionSound ? <VolumeUpIcon /> : <VolumeOffIcon />}</span>
            </button>
          </div>
        </div>
      )}
      <Dialog open={pressYourLuckModal}>
        <div className='spin-dialog'>
          <h3 className='spin-dialog-title'>Press Your Luck</h3>

          <p className='spin-dialog-text'>
            You can cash in all your spins and points for a chance to win a
            grand prize or lose everything.
          </p>

          <div className='spin-dialog-buttons'>
            <button
              onClick={() => {
                setPressYourLuck(true);
                setPressYourLuckModal(false);
              }}>
              Yes
            </button>
            <button
              onClick={() => {
                setPressYourLuckModal(false);
                setDisabled(spins === 0);
              }}>
              No
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        disableScrollLock
        open={prizeModal}
        classes={{
          paper: classes.dialog,
        }}>
        {alertedPrize && (
          <div className='prize-dialog'>
            <button
              className='prize-dialog-close'
              onClick={() => {
                setPrizeModal(false);
                setAlertedPrize(null);

                if (awarderPrize) {
                  handleLogout(true);
                }
              }}>
              <CloseIcon style={{ fontSize: 18, color: '#B0B0AF' }} />
            </button>
            <p className='prize-dialog-name'>{alertedPrize.name}</p>
            <p className='prize-dialog-description'>
              {alertedPrize.fulfillmentDescription}
            </p>
            {alertedPrize.imageFile && (
              <div className='prize-dialog-image'>
                <img
                  src={alertedPrize?.imageFile?.url}
                  alt={alertedPrize.imageFile.description}
                />
              </div>
            )}

            <div className='prize-dialog-buttons' />
          </div>
        )}
      </Dialog>
      <Dialog open={loseModal}>
        <div
          className={pressYourLuck ? 'lose-press-dialog' : 'lose-spin-dialog'}>
          <div className='lose-dialog-title'>Thank you for Participating</div>
          <div className='lose-dialog-description'>Better luck next time</div>
          <div className='lose-dialog-buttons '>
            <button onClick={() => handleLogout(true)}>Log out</button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Spin;
