import axios from 'axios';
import { api } from 'settings';

export const FETCH_FILES = 'FETCH_FILES';

export const fetchFiles = () => (dispatch) => {
  return axios.get(`${api.files.get}`).then((response) => {
    dispatch({
      type: FETCH_FILES,
      payload: response.data,
    });
    return response.data;
  });
};

export const addFile = (file) => () => {
  return axios.post(`${api.files.post}`, file).then((response) => {
    return response.data;
  });
};

export const updateFile = (file) => () => {
  return axios.put(`${api.files.put}/${file.id}`, file).then((response) => {
    return response.data;
  });
};
