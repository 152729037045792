import React from 'react';
import { Form, Field } from 'react-final-form';
import LockIcon from '@material-ui/icons/Lock';

const required = (value) => (value ? undefined : 'This field is required');

const minLength = (min) => (string) =>
  string && string.length < min
    ? `The password must be at least ${min} characters long `
    : undefined;
const minLength6 = minLength(6);

const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

const renderField = ({ input, label, meta: { touched, error, warning } }) => (
  <div className='form-group'>
    <label>{label}</label>
    <div className='input'>
      <div className='icon'>
        <LockIcon style={{ fontSize: 16 }} />
      </div>
      <div>
        <input {...input} placeholder={label} />
      </div>
      {touched && error && <span className='error'>{error}</span>}
    </div>
  </div>
);

const ChangePasswordForm = (props) => {
  return (
    <Form
      onSubmit={props.onSubmit}
      validate={({ newPassword, confirmPassword }) => {
        const errors = {};

        if (newPassword !== confirmPassword) {
          errors.confirmPassword = `The passwords doesn't match`;
        }

        return errors;
      }}
      render={({ submitting, pristine, handleSubmit }) => (
        <form onSubmit={handleSubmit} autoComplete='none'>
          <input type='hidden' name='username' value={props.user.userName} />
          <Field
            autoComplete='none'
            component={renderField}
            className='form-input'
            name='oldPassword'
            type='password'
            label='Old Password'
            disabled={submitting}
            validate={composeValidators(required, minLength6)}
          />
          <Field
            autoComplete='none'
            component={renderField}
            className='form-input'
            name='newPassword'
            type='password'
            label='New Password'
            disabled={submitting}
            validate={composeValidators(required, minLength6)}
          />
          <Field
            autoComplete='none'
            component={renderField}
            className='form-input'
            name='confirmPassword'
            type='password'
            label='Confirm Password'
            disabled={submitting}
            validate={composeValidators(required, minLength6)}
          />
          <button
            type='submit'
            className='submit'
            disabled={submitting || pristine}>
            Change
          </button>
        </form>
      )}
    />
  );
};

export default ChangePasswordForm;
