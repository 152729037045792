import axios from 'axios';
import { api } from 'settings';
import { enqueueSnackbar } from 'store/notifications/actions';

export const CLEAR_QUESTIONS = 'CLEAR_QUESTIONS';
export const FETCH_QUESTIONS = 'FETCH_QUESTIONS';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const ADD_QUESTION = 'ADD_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const DELETE_QUESTIONS = 'DELETE_QUESTIONS';

export const clearQuestions = () => (dispatch) =>
  dispatch({
    type: CLEAR_QUESTIONS,
  });

export const fetchQuestions = (campaignId) => (dispatch) => {
  return axios.get(`${api.questions.getAll}/${campaignId}`).then((response) => {
    dispatch({
      type: FETCH_QUESTIONS,
      payload: response.data,
    });
    return response.data;
  });
};

export const addQuestion = (data, campaignId) => (dispatch) => {
  return axios
    .post(`${api.questions.post}/${campaignId}`, data)
    .then((response) => {
      dispatch({
        type: ADD_QUESTION,
        payload: response.data,
      });
      return response.data;
    });
};

export const updateQuestion = (data) => (dispatch) => {
  return axios.put(`${api.questions.put}/${data.id}`, data).then((response) => {
    dispatch({
      type: UPDATE_QUESTION,
      payload: response.data,
    });
    return response.data;
  });
};

export const deleteQuestion = (id) => (dispatch) => {
  return axios.delete(`${api.questions.delete}/${id}`).then((response) => {
    dispatch({
      type: DELETE_QUESTION,
      payload: response.data,
    });

    dispatch(
      enqueueSnackbar({
        message: 'The question has been deleted',
        options: {
          variant: 'success',
        },
      })
    );

    return response.data;
  });
};
export const deleteAllQuestions = (campaignId) => (dispatch) => {
  return axios
    .delete(`${api.questions.deleteAll}/${campaignId}`)
    .then((response) => {
      dispatch(
        enqueueSnackbar({
          message: 'All questions have been deleted',
          options: {
            variant: 'success',
          },
        })
      );

      dispatch({
        type: DELETE_QUESTIONS,
      });

      return response.data;
    });
};

export const downloadTemplate = () => (dispatch) => {
  return axios
    .post(`${api.questions.downloadTemplate}`, null, {
      headers: {
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    })
    .then((response) => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const file = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const fileURL = window.URL.createObjectURL(file);
      a.href = fileURL;
      a.download = response.headers['content-disposition'].split('=')[1];
      a.click();
      return response.data;
    });
};

export const uploadTemplate = (formData, campaignId) => (dispatch) => {
  return axios
    .post(`${api.questions.uploadTemplate}/${campaignId}`, formData, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    })
    .then((response) => {
      dispatch(
        enqueueSnackbar({
          message: 'All question have been uploaded',
          options: {
            variant: 'success',
          },
        })
      );
      dispatch({
        type: FETCH_QUESTIONS,
        payload: response.data,
      });
      return response.data;
    });
};
