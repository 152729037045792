import axios from 'axios';
import { api } from 'settings';

export const REQUEST_START = 'REQUEST_START';
export const REQUEST_STOP = 'REQUEST_STOP';
export const REQUEST_CLEAN = 'REQUEST_CLEAN';
export const FETCH_THEMES = 'FETCH_THEMES';
export const SET_THEME = 'SET_THEME';

export const requestStart = (payload) => ({
  type: REQUEST_START,
  payload,
});

export const requestStop = (payload) => ({
  type: REQUEST_STOP,
  payload,
});

export const requestClean = () => ({
  type: REQUEST_CLEAN,
});

export const setTheme = (payload) => ({
  type: SET_THEME,
  payload,
});

export const fetchThemes = () => (dispatch) => {
  return axios.get(`${api.themes}`).then((response) => {
    dispatch({
      type: FETCH_THEMES,
      payload: response.data,
    });
    return response.data;
  });
};
