import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { RenderInputText, RenderAvatarField } from 'components/FormElements';
import Dialog from '@material-ui/core/Dialog';
import UploadTemplate from 'components/UploadTemplate';
import Buttons from '../Buttons';
import './Users.scss';
import {
  fetchUsers,
  downloadTemplate,
  uploadTemplate,
  deleteAllUsers,
  updateUser,
  addUser,
  deleteUser,
  sendNotificationEmail,
  clearUsers,
} from 'store/users/actions';
import CheckIcon from '@material-ui/icons/Check';
import sendEmail from './assets/paperairplane@2x.png';

const required = (value) => (value ? undefined : 'This field is required');
const email = (value) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  )
    ? undefined
    : 'Please enter valid email address';
const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

const Users = () => {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteAllDialog, setDeleteAllDialog] = useState(false);
  const [sendEmailDialog, setSendEmailDialog] = useState(false);
  const [userForEmail, setUserForEmail] = useState(false);
  const [selectedUser, selectUser] = useState({});
  const dispatch = useDispatch();
  const { userId, campaignId } = useParams();
  const history = useHistory();

  const { users, client } = useSelector((state) => ({
    users: state.users.data,
    client: state?.clients?.client,
  }));

  useEffect(() => {
    dispatch(fetchUsers(campaignId));

    return () => {
      dispatch(clearUsers());
    };
  }, [dispatch, campaignId]);

  const selectUserHandler = useCallback((user) => {
    selectUser(user);
  }, []);

  const sendEmailHandler = useCallback(
    (user) => {
      return dispatch(sendNotificationEmail(campaignId, user.id)).then(() =>
        dispatch(fetchUsers(campaignId))
      );
    },
    [campaignId, dispatch]
  );

  const submitHandler = useCallback(
    (user, form) => {
      const {
        firstName,
        lastName,
        email,
        location,
        manager,
        managerAvatarFile,
      } = user;

      const handler = user.id ? updateUser : addUser;
      const data = user.id
        ? {
            firstName,
            lastName,
            email,
            location,
            manager,
            managerAvatarFile,
          }
        : user;

      const withAvatar =
        managerAvatarFile &&
        managerAvatarFile?.base64String !==
          selectedUser?.managerAvatarFile?.base64String;

      return dispatch(handler(data, withAvatar, campaignId, client.id)).then(
        () => {
          selectUser({});
          form.restart({});
          return dispatch(fetchUsers(campaignId));
        }
      );
    },
    [client, dispatch, campaignId, selectedUser]
  );

  const deleteHandler = useCallback(
    (...rest) => {
      if (selectedUser) {
        return dispatch(deleteUser(campaignId, selectedUser.id)).then(() => {
          selectUser({});
          return dispatch(fetchUsers(campaignId));
        });
      }
    },
    [campaignId, dispatch, selectedUser]
  );

  return (
    <>
      <div className='steps-content users-content'>
        <h3>Users</h3>
        <div className='user-section1'>
          <button
            className='gray-btn light delete-user'
            onClick={() => setDeleteAllDialog(true)}>
            Delete All Users
          </button>
          <div className='block'>
            <span className='inner-title'>Bulk Operations</span>
            <div className='btn-block'>
              <button
                className='gray-btn light'
                onClick={() => dispatch(downloadTemplate(campaignId))}>
                Download Template
              </button>

              <UploadTemplate
                setFile={(file) => {
                  dispatch(uploadTemplate(file, campaignId));
                }}
                accept='.xlsx'>
                <button className='gray-btn light'>Upload Template</button>
              </UploadTemplate>
            </div>
          </div>
        </div>
        <h3>Users</h3>
        <div className='user-section'>
          <Form
            initialValues={selectedUser}
            onSubmit={submitHandler}
            render={({ pristine, submitting, handleSubmit, values, form }) => (
              <form
                onSubmit={(event) => {
                  return handleSubmit(event)?.then(() => {
                    form.restart();
                  });
                }}>
                <div className='button-row'>
                  <div className='switcher'>
                    <div className='inner-title'>Manual Input Users</div>
                  </div>
                </div>

                <div className='multi-choice'>
                  <div className='row-block  user-fields flex justify-between mb-10'>
                    <Field
                      name={`firstName`}
                      label={`First Name`}
                      component={RenderInputText}
                      disabled={submitting}
                      validate={required}
                    />
                  </div>
                  <div className='row-block user-fields flex justify-between mb-10'>
                    <Field
                      name={`lastName`}
                      label={`Last Name`}
                      component={RenderInputText}
                      disabled={submitting}
                      validate={required}
                    />
                  </div>
                  <div className='row-block user-fields flex justify-between mb-10'>
                    <Field
                      name={`email`}
                      label={`Email`}
                      component={RenderInputText}
                      disabled={submitting}
                      validate={composeValidators(required, email)}
                    />
                  </div>
                  <div className='row-block user-fields flex justify-between mb-10'>
                    <Field
                      name={`location`}
                      label={`Location`}
                      component={RenderInputText}
                      disabled={submitting}
                    />
                  </div>
                  <div className='row-block user-fields flex justify-between mb-10'>
                    <Field
                      name={`manager`}
                      label={`Manager`}
                      component={RenderInputText}
                      disabled={submitting}
                    />
                  </div>
                  <Field
                    name='managerAvatarFile'
                    label='Add Manager Avatar'
                    component={RenderAvatarField}
                    disabled={submitting}
                    description='Avatar Image For Manager'
                  />
                </div>

                <div className='btn-block'>
                  {values?.id ? (
                    <>
                      <button
                        type='submit'
                        className='update green-btn'
                        disabled={!client}>
                        Update User
                      </button>
                      <button
                        className='delete red-btn'
                        type='button'
                        onClick={(e) => setDeleteDialog(true)}
                        disabled={!client}>
                        Delete User
                      </button>
                      <button
                        className='update gray-btn2'
                        type='button'
                        onClick={(e) => {
                          selectUser({});
                          form.restart({});
                        }}>
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type='submit'
                        className='update green-btn'
                        disabled={pristine}>
                        Add User
                      </button>
                      <button
                        className='update gray-btn2'
                        type='button'
                        onClick={(e) => {
                          selectUser({});
                          form.restart({});
                        }}>
                        Clear
                      </button>
                    </>
                  )}
                </div>

                {/* <div>
                  <pre>{JSON.stringify(values, undefined, 2)}</pre>
                </div> */}
              </form>
            )}
          />
        </div>

        {users && users.length > 0 && (
          <div className='user-list'>
            <table>
              <thead>
                <tr>
                  <th>
                    <span>First Name</span>
                  </th>
                  <th>
                    <span>Last Name</span>
                  </th>
                  <th>
                    <span>Employee ID</span>
                  </th>
                  <th>
                    <span>Location</span>
                  </th>
                  <th>
                    <span>Email</span>
                  </th>
                  <th>
                    <span>Send Email</span>
                  </th>
                  <th>
                    <span>Select</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>
                      <span>{user.firstName}</span>
                    </td>
                    <td>
                      <span>{user.lastName}</span>
                    </td>
                    <td>
                      <span>{user.id}</span>
                    </td>
                    <td>
                      <span>{user.location}</span>
                    </td>
                    <td>
                      <span>{user.email}</span>
                    </td>
                    <td>
                      <div className='email-icons'>
                        <div
                          className={`indicator ${
                            user.isEmailSent ? 'active' : ''
                          }`}>
                          <CheckIcon
                            style={{
                              color: 'white',
                              padding: 0,
                              fontSize: '20px',
                            }}
                          />
                        </div>
                        <button
                          type='button'
                          className='send-button'
                          onClick={() => {
                            setSendEmailDialog(true);
                            setUserForEmail(user);
                          }}>
                          <img src={sendEmail} alt='resend email' />
                        </button>
                      </div>
                    </td>
                    <td className='select-radio'>
                      <input
                        type='radio'
                        checked={selectedUser?.id === user.id}
                        onChange={() => selectUserHandler(user)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <Buttons
          onPrev={() => history.push(`/user/${userId}/questions/${campaignId}`)}
          onSave={() => history.push(`/user/${userId}/report`)}
          disabled={false}
        />
        <Dialog open={sendEmailDialog}>
          <div className='quit-dialog'>
            <h3 className='quit-dialog-title2'>
              Are you sure you want to send email to User?
            </h3>

            <div className='quit-dialog-buttons'>
              <button
                type='button'
                onClick={() => {
                  setSendEmailDialog(false);
                  sendEmailHandler(userForEmail);
                }}>
                Ok
              </button>
              <button type='button' onClick={() => setSendEmailDialog(false)}>
                Cancel
              </button>
            </div>
          </div>
        </Dialog>

        <Dialog open={deleteDialog}>
          <div className='quit-dialog'>
            <h3 className='quit-dialog-title2'>
              Are you sure you want to delete this user?
            </h3>

            <div className='quit-dialog-buttons'>
              <button
                type='button'
                onClick={() =>
                  deleteHandler().then(() => setDeleteDialog(false))
                }>
                Yes
              </button>
              <button type='button' onClick={() => setDeleteDialog(false)}>
                No
              </button>
            </div>
          </div>
        </Dialog>

        <Dialog open={deleteAllDialog}>
          <div className='quit-dialog'>
            <h3 className='quit-dialog-title2'>
              Are you sure you want to delete all users?
            </h3>

            <div className='quit-dialog-buttons'>
              <button
                type='button'
                onClick={() => {
                  setDeleteAllDialog(false);
                  dispatch(deleteAllUsers(campaignId));
                }}>
                Yes
              </button>
              <button type='button' onClick={() => setDeleteAllDialog(false)}>
                No
              </button>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default Users;
