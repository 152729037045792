import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Header from './Header';
import {
  logout,
  toggleLogoutDialog,
  toggleResetPassDialog,
} from 'store/authentication/actions';
import {
  getActiveCanpaign,
  getQuestionIndex,
  getQuestions,
} from 'store/games/selectors';
import Select from 'react-select';
import { selectStylesLight } from 'components/selectStyles';
import './style.scss';
import BrandLogo from 'containers/User/BrandLogo';

let Welcome = ({
  user,
  activeCanpaign,
  toggleResetPassDialog,
  toggleLogoutDialog,
  storedQuestionIndex,
  questions,
  gameDetails,
}) => {
  const history = useHistory();
  const { userId, campaignId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      history.push('/login');
    }
  }, [history, user]);

  useEffect(() => {
    if (activeCanpaign) {
      window.history.replaceState(
        null,
        '',
        `/user/${userId}/welcome/${activeCanpaign.id}`
      );
    }
  }, [activeCanpaign, campaignId, history, userId]);

  const handleLogout = () => {
    dispatch(logout()).then(() => {
      history.push('/login');
    });
  };

  const selectCampaignHandler = (value) => {
    history.push(`/user/${userId}/welcome/${value.id}`);
  };

  const startHandler = useCallback(() => {
    const page = storedQuestionIndex === questions.length ? 'spin' : 'quiz';

    if (!activeCanpaign.campaignType.isSmartSpinAllowed && page === 'spin') {
      return;
    }

    history.push(`/user/${userId}/${page}/${activeCanpaign.id}`);
  }, [activeCanpaign, history, questions, storedQuestionIndex, userId]);

  if (!user || !gameDetails) return null;

  if (!activeCanpaign) {
    return (
      <div className='nothing-is-assigned'>
        <div className='inner-content'>
          <h2>Nothing is assigned to you. Please contact your manager.</h2>
          <div className='buttons'>
            <button onClick={handleLogout}>Log Out</button>
          </div>
        </div>
      </div>
    );
  }

  const activeCanpaigns = gameDetails && gameDetails[0].campaigns;

  return (
    <div className='welcome'>
      <div className='logo'>
        <BrandLogo />
      </div>

      <Header
        user={user}
        toggleResetPassDialog={toggleResetPassDialog}
        toggleLogoutDialog={toggleLogoutDialog}
      />
      {activeCanpaigns && activeCanpaigns.length > 1 && (
        <div className='campaign-list'>
          <Select
            placeholder='Select campaign'
            styles={selectStylesLight}
            menuColor='red'
            value={activeCanpaign}
            isDisabled={!activeCanpaigns}
            options={activeCanpaigns}
            onChange={selectCampaignHandler}
            getOptionLabel={(option) =>
              `${option?.campaignName} - [ ${option?.campaignType?.campaignTypeDescription} ]` ||
              option.label
            }
          />
        </div>
      )}

      <div className='inner'>
        <h3>
          Welcome to SmartSpin<sup style={{ fontSize: '50%' }}>SM</sup>
        </h3>
        <p>Are you ready to test your knowledge and win some great prizes?</p>
        <p>
          SmartSpin
          <sup style={{ fontSize: '50%' }}>SM</sup> is simple and fun.
        </p>
        <p>Here is how to play:</p>
        <ul>
          <li>We ask you 10 random questions</li>
          <li>Each question answered correctly earns you a spin</li>
          <li>Use your spins to earn points</li>
          <li>More points leads to a greater prize</li>
        </ul>
        <p>Bonus Points and Prizes:</p>
        <ul>
          <li>
            Land on SmartSpin<sup style={{ fontSize: '50%' }}>SM</sup> and earn
            extra points{' '}
          </li>
          <li>
            Cash in all your spins and points for a chance to win the grand
            prize? Land on “Press Your Luck” and test if it is your lucky day
          </li>
        </ul>
        <br />
        <div className='buttons'>
          <button onClick={startHandler} disabled={!questions || !gameDetails}>
            Start
          </button>

          {/* <button
            onClick={() =>
              history.push(`/user/${userId}/quiz/${activeCanpaign.id}`)
            }
            disabled={
              !questions ||
              storedQuestionIndex === questions.length ||
              !gameDetails
            }>
            Start Quiz
          </button>

          {activeCanpaign.campaignType.isSmartSpinAllowed && (
            <button
              onClick={() =>
                history.push(`/user/${userId}/spin/${activeCanpaign.id}`)
              }
              disabled={
                !questions ||
                storedQuestionIndex !== questions.length ||
                !gameDetails
              }>
              Start SmartSpin
            </button>
          )} */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  gameDetails: state.games.details,
  activeCanpaign: getActiveCanpaign(state.games),
  questions: getQuestions(state.games),
  storedQuestionIndex: getQuestionIndex(state.games),
});

const mapDispatchToProps = {
  logout,
  toggleLogoutDialog,
  toggleResetPassDialog,
};

Welcome = connect(mapStateToProps, mapDispatchToProps)(Welcome);
export default Welcome;
