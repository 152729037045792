import React from 'react';
import { Field, Form } from 'react-final-form';
import CloseIcon from '@material-ui/icons/Close';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import Buttons from '../Buttons';
import {
  RenderDatePicker,
  RenderRadio,
  RenderInputTextGray,
  RenderInputText,
  RenderTextArea,
  RenderFileField,
} from 'components/FormElements';
import moment from 'moment';

const required = (value) => (value ? undefined : 'This field is required');
const numberOnly = (value) => (parseInt(value) ? parseInt(value) : '');
// const requiredArray = (value) =>
//   value && value.length > 0 ? undefined : 'This field is required';
// const composeValidators =
//   (...validators) =>
//   (value) =>
//     validators.reduce(
//       (error, validator) => error || validator(value),
//       undefined
//     );

const GameSetupForm = (props) => {
  const {
    campaignTypes,
    onSubmit,
    initialValues,
    selectedCampaign,
    children,
    selectCampaign,
    newCampaign,
  } = props;

  return (
    <Form
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      validate={(values) => {
        const errors = {};
        return errors;
      }}
      onSubmit={onSubmit}
      render={({
        pristine,
        submitting,
        handleSubmit,
        values,
        form,
        ...rest
      }) => (
        <form onSubmit={handleSubmit}>
          <div className='row-block mr-40 flex'>
            <div className='company-name-block'>
              <h3>Campaign Name</h3>
              <Field
                name='campaignName'
                component={RenderInputTextGray}
                disabled={
                  submitting ||
                  !selectedCampaign ||
                  selectedCampaign.hasCampaignUserProgress
                }
                validate={required}
              />
            </div>
            {children}
            <div className='new-campaign-block'>
              <h3>&nbsp;</h3>
              <button
                type='button'
                disabled={!campaignTypes}
                onClick={() => {
                  selectCampaign();
                  form.restart(newCampaign);
                }}>
                Setup New Campaign
              </button>
            </div>
          </div>

          <h3>Campaign Setup</h3>
          <div className='row-block mr-40 flex justify-between'>
            <div className='block w-1'>
              <span className='inner-title'>Campaign Start Date</span>
              <div className='inner-wrap'>
                <Field
                  component={RenderDatePicker}
                  name='startDate'
                  placeholderText='Month / Day / Year'
                  dateFormat='MM/dd/yyyy'
                  minDate={moment().toDate()}
                  disabled={
                    submitting ||
                    !selectedCampaign ||
                    selectedCampaign.hasCampaignUserProgress
                  }
                  validate={required}
                />
              </div>
            </div>
            <div className='block w-1'>
              <span className='inner-title'>Campaign End Date</span>
              <div className='inner-wrap'>
                <Field
                  component={RenderDatePicker}
                  name='endDate'
                  placeholderText='Month / Day / Year'
                  dateFormat='MM/dd/yyyy'
                  minDate={moment().toDate()}
                  disabled={
                    submitting ||
                    !selectedCampaign ||
                    selectedCampaign.hasCampaignUserProgress
                  }
                  validate={required}
                  parse={(value) => {
                    return moment(value).endOf('day').format();
                  }}
                />
              </div>
            </div>
            <div className='block w-4'>
              <span className='inner-title'>Game</span>
              <div className='inner-wrap'>
                <div className='choose-game'>
                  {campaignTypes &&
                    campaignTypes
                      .sort((a, b) =>
                        a.campaignTypeDescription.length <
                        b.campaignTypeDescription.length
                          ? -1
                          : 1
                      )
                      .map((type, index) => (
                        <Field
                          key={index}
                          name='campaignTypeId'
                          type='radio'
                          value={type.id}
                          label={type.campaignTypeDescription}
                          component={RenderRadio}
                          changeCallback={() => {
                            if (type.isPressYourLuckAllowed) {
                              if (values.id) {
                                form.change('pressYourLuckPrizes', {
                                  green: values.prizes.find(
                                    (e) =>
                                      e.isPressYourLuckPrize && e.rank === 1
                                  ) || { isPressYourLuckPrize: true, rank: 1 },
                                  yellow: values.prizes.find(
                                    (e) =>
                                      e.isPressYourLuckPrize && e.rank === 2
                                  ) || { isPressYourLuckPrize: true, rank: 2 },
                                  orange: values.prizes.find(
                                    (e) =>
                                      e.isPressYourLuckPrize && e.rank === 3
                                  ) || { isPressYourLuckPrize: true, rank: 3 },
                                  logo: values.prizes.find(
                                    (e) => e.isPressYourLuckPrize && !e.rank
                                  ) || {
                                    isPressYourLuckPrize: true,
                                    rank: null,
                                  },
                                });
                              } else {
                                form.change('pressYourLuckPrizes', {
                                  green: {
                                    isPressYourLuckPrize: true,
                                    rank: 1,
                                  },
                                  yellow: {
                                    isPressYourLuckPrize: true,
                                    rank: 2,
                                  },
                                  orange: {
                                    isPressYourLuckPrize: true,
                                    rank: 3,
                                  },
                                  logo: {
                                    isPressYourLuckPrize: true,
                                    rank: null,
                                  },
                                });
                              }
                            } else {
                              form.change('pressYourLuckPrizes', {});
                            }

                            if (type.isSmartSpinAllowed) {
                              if (values.id) {
                                form.change(
                                  'wheelPrizes',
                                  values.prizes.filter(
                                    (e) => !e.isPressYourLuckPrize
                                  )
                                );
                              } else {
                                form.change('wheelPrizes', [
                                  { isPressYourLuckPrize: false, rank: 1 },
                                  { isPressYourLuckPrize: false, rank: 2 },
                                  { isPressYourLuckPrize: false, rank: 3 },
                                ]);
                              }
                            } else {
                              form.change('wheelPrizes', []);
                            }

                            form.change('campaignType', type);
                          }}
                          disabled={
                            submitting ||
                            !selectedCampaign ||
                            selectedCampaign.hasCampaignUserProgress
                          }
                        />
                      ))}
                </div>
              </div>
            </div>
          </div>
          {values?.campaignType?.isSmartSpinAllowed && (
            <div className='quiz-smartSpin'>
              <h3>SmartSpin Game Prizes Setup</h3>

              <FieldArray
                name='wheelPrizes'
                render={({ fields }) => (
                  <>
                    <div className='prizes'>
                      {fields.map((name, index) => (
                        <div className='prize' key={name}>
                          <div className='inner-title'>
                            <span className='text'>Prize {index + 1}</span>
                            <button
                              type='button'
                              className='remove'
                              onClick={() => fields.remove(index)}>
                              <CloseIcon />
                            </button>
                          </div>
                          <div className='inner-wrap'>
                            <Field
                              name={`${name}.imageFile`}
                              component={RenderFileField}
                              disabled={
                                submitting ||
                                selectedCampaign.hasCampaignUserProgress
                              }
                              validate={required}
                            />

                            <Field
                              name={`${name}.name`}
                              label={`Prize ${index + 1} Name`}
                              component={RenderInputText}
                              disabled={
                                submitting ||
                                selectedCampaign.hasCampaignUserProgress
                              }
                              validate={required}
                            />
                            <Field
                              name={`${name}.pointsNeededMinimum`}
                              label=' Minimum Points Needed for Prize:'
                              component={RenderInputText}
                              disabled={
                                submitting ||
                                selectedCampaign.hasCampaignUserProgress
                              }
                              validate={required}
                              parse={numberOnly}
                            />
                            <Field
                              name={`${name}.pointsNeededMaximum`}
                              label='Maximum Points Needed for Prize:'
                              component={RenderInputText}
                              disabled={
                                submitting ||
                                selectedCampaign.hasCampaignUserProgress
                              }
                              validate={required}
                              parse={numberOnly}
                            />
                            <Field
                              name={`${name}.winnerTotal`}
                              label='Prize Winners (Total):'
                              component={RenderInputText}
                              disabled={
                                submitting ||
                                selectedCampaign.hasCampaignUserProgress
                              }
                              validate={required}
                              parse={numberOnly}
                            />
                            <Field
                              name={`${name}.fulfillmentDescription`}
                              label='Prize Fulfillment  Description: '
                              component={RenderTextArea}
                              disabled={
                                submitting ||
                                selectedCampaign.hasCampaignUserProgress
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className='add-more-button'>
                      <button
                        type='button'
                        className='add-more'
                        disabled={submitting}
                        onClick={() =>
                          fields.push({
                            imageFile: null,
                            rank: fields.length + 1,
                            pointsNeededMinimum: null,
                            pointsNeededMaximum: null,
                            isPressYourLuckPrize: false,
                            name: '',
                            winnerTotal: null,
                            fulfillmentDescription: '',
                            isDeleted: false,
                          })
                        }>
                        Add Another Prize
                      </button>
                    </div>
                  </>
                )}
              />

              <div className='row-block  flex justify-between'>
                <div className='block w-3'>
                  <span className='inner-title'>
                    Logo Prize (SmartSpin Wheel)
                  </span>
                  <div className='inner-wrap'>
                    <Field
                      name='bonusPointsAwarded'
                      label='Points Awarded For Logo:'
                      component={RenderInputText}
                      disabled={
                        submitting || selectedCampaign.hasCampaignUserProgress
                      }
                      validate={required}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {values?.campaignType?.isPressYourLuckAllowed && (
            <div className='quiz-smartSpin'>
              <h3>Press Your Luck Game Prizes Setup</h3>
              <div className='prizes'>
                <div className='prize'>
                  <div className='inner-title'>
                    <span className='text'>Green Prize Square</span>
                  </div>
                  <div className='inner-wrap'>
                    <Field
                      name='pressYourLuckPrizes.green.imageFile'
                      component={RenderFileField}
                      disabled={
                        submitting || selectedCampaign.hasCampaignUserProgress
                      }
                      validate={required}
                    />
                    <Field
                      name='pressYourLuckPrizes.green.name'
                      label='Green Prize Name:'
                      component={RenderInputText}
                      disabled={
                        submitting || selectedCampaign.hasCampaignUserProgress
                      }
                      validate={required}
                    />
                    <Field
                      name='pressYourLuckPrizes.green.winnerTotal'
                      label='Prize Winners (Total):'
                      component={RenderInputText}
                      disabled={
                        submitting || selectedCampaign.hasCampaignUserProgress
                      }
                      validate={required}
                    />
                    <Field
                      name='pressYourLuckPrizes.green.fulfillmentDescription'
                      label='Prize Fulfillment  Description: '
                      component={RenderTextArea}
                      disabled={
                        submitting || selectedCampaign.hasCampaignUserProgress
                      }
                    />
                  </div>
                </div>
                <div className='prize'>
                  <div className='inner-title'>
                    <span className='text'>Yellow Prize Square</span>
                  </div>
                  <div className='inner-wrap'>
                    <Field
                      name='pressYourLuckPrizes.yellow.imageFile'
                      component={RenderFileField}
                      disabled={
                        submitting || selectedCampaign.hasCampaignUserProgress
                      }
                      validate={required}
                    />
                    <Field
                      name='pressYourLuckPrizes.yellow.name'
                      label='Yellow Prize Name:'
                      component={RenderInputText}
                      disabled={
                        submitting || selectedCampaign.hasCampaignUserProgress
                      }
                      validate={required}
                    />
                    <Field
                      name='pressYourLuckPrizes.yellow.winnerTotal'
                      label='Prize Winners (Total):'
                      component={RenderInputText}
                      disabled={
                        submitting || selectedCampaign.hasCampaignUserProgress
                      }
                      validate={required}
                    />
                    <Field
                      name='pressYourLuckPrizes.yellow.fulfillmentDescription'
                      label='Prize Fulfillment  Description: '
                      component={RenderTextArea}
                      disabled={
                        submitting || selectedCampaign.hasCampaignUserProgress
                      }
                    />
                  </div>
                </div>
                <div className='prize'>
                  <div className='inner-title'>
                    <span className='text'>Orange Prize Square</span>
                  </div>
                  <div className='inner-wrap'>
                    <Field
                      name='pressYourLuckPrizes.orange.imageFile'
                      component={RenderFileField}
                      disabled={
                        submitting || selectedCampaign.hasCampaignUserProgress
                      }
                      validate={required}
                    />
                    <Field
                      name='pressYourLuckPrizes.orange.name'
                      label='Orange Prize Name:'
                      component={RenderInputText}
                      disabled={
                        submitting || selectedCampaign.hasCampaignUserProgress
                      }
                      validate={required}
                    />
                    <Field
                      name='pressYourLuckPrizes.orange.winnerTotal'
                      label='Prize Winners (Total):'
                      component={RenderInputText}
                      disabled={
                        submitting || selectedCampaign.hasCampaignUserProgress
                      }
                      validate={required}
                    />
                    <Field
                      name='pressYourLuckPrizes.orange.fulfillmentDescription'
                      label='Prize Fulfillment  Description: '
                      component={RenderTextArea}
                      disabled={
                        submitting || selectedCampaign.hasCampaignUserProgress
                      }
                    />
                  </div>
                </div>
                <div className='prize'>
                  <div className='inner-title'>
                    <span className='text'>
                      Logo Prize Square (Press Your Luck)
                    </span>
                  </div>
                  <div className='inner-wrap'>
                    <Field
                      name='pressYourLuckPrizes.logo.imageFile'
                      component={RenderFileField}
                      disabled={
                        submitting || selectedCampaign.hasCampaignUserProgress
                      }
                      validate={required}
                    />
                    <Field
                      name='pressYourLuckPrizes.logo.name'
                      label='Logo Prize Name:'
                      component={RenderInputText}
                      disabled={
                        submitting || selectedCampaign.hasCampaignUserProgress
                      }
                      validate={required}
                    />
                    <Field
                      name='pressYourLuckPrizes.logo.winnerTotal'
                      label='Prize Winners (Total):'
                      component={RenderInputText}
                      disabled={
                        submitting || selectedCampaign.hasCampaignUserProgress
                      }
                      validate={required}
                    />
                    <Field
                      name='pressYourLuckPrizes.logo.fulfillmentDescription'
                      label='Prize Fulfillment  Description: '
                      component={RenderTextArea}
                      disabled={
                        submitting || selectedCampaign.hasCampaignUserProgress
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* <div>
            <pre>{JSON.stringify(values, undefined, 2)}</pre>
          </div> */}
          <Buttons
            onPrev={props.onPrev}
            onSave={handleSubmit}
            onNext={() => {
              form.change('action', 'next');
              if (!pristine) {
                handleSubmit();
              } else {
                props.onNext();
              }
            }}
            disabled={submitting || !selectedCampaign}
          />
        </form>
      )}
    />
  );
};

export default GameSetupForm;
