import React, { useCallback, useEffect, useState, Fragment } from 'react';
import './Report.scss';
import Select from 'react-select';
import { selectStyles } from 'components/selectStyles';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAgentParticipation,
  fetchAverageQuizScore,
  fetchQuizQuestionOverviewDetails,
  fetchQuizUserOverview,
  fetchUserPointsPrizesOverview,
  clearReports,
  downloadReport,
  downloadUserReport,
  downloadQuestionReport,
} from 'store/reports/actions';
import Chart from 'react-google-charts';
import Dialog from '@material-ui/core/Dialog';
import ClearIcon from '@material-ui/icons/Clear';

import { fetchCampaignsLight } from 'store/campaigns/actions';

const Report = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId, campaignId } = useParams();

  const [reportType, setReportType] = useState('questions');
  const [selectedCampaign, selectCampaign] = useState('');
  const [questionsReportModal, setQuestionsReportModal] = useState(null);
  const [usersReportModal, setUsersReportModal] = useState(null);

  const {
    availableCampaigns,
    campaignsLight,
    agentParticipation,
    averageQuizScore,
    quizQuestionOverviewDetails,
    quizUserOverview,
    userPointsPrizesOverview,
    clientId,
  } = useSelector((state) => ({
    availableCampaigns: state.campaigns.available,
    campaignsLight: state.campaigns.light,
    agentParticipation: state.reports.agentParticipation,
    averageQuizScore: state.reports.averageQuizScore,
    quizQuestionOverviewDetails: state.reports.quizQuestionOverviewDetails,
    quizUserOverview: state.reports.quizUserOverview,
    userPointsPrizesOverview: state.reports.userPointsPrizesOverview,
    clientId: state.authentication.user.clientId,
  }));

  useEffect(() => {
    if (campaignId) {
      dispatch(fetchAgentParticipation(campaignId));
      dispatch(fetchAverageQuizScore(campaignId));
      dispatch(fetchQuizQuestionOverviewDetails(campaignId));
      dispatch(fetchQuizUserOverview(campaignId));
      dispatch(fetchUserPointsPrizesOverview(campaignId));
      return () => {
        dispatch(clearReports());
      };
    }
  }, [campaignId, dispatch]);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchCampaignsLight(clientId));
    }
  }, [clientId, dispatch]);

  const selectCampaignHandler = useCallback(
    (value) => {
      selectCampaign(value);
      history.push(
        value?.campaignId
          ? `/user/${userId}/report/${value.campaignId}`
          : `/user/${userId}/report`
      );
    },
    [history, userId]
  );

  return (
    <>
      <div className='steps-content report-content'>
        <div className='active-campaign-block'>
          <h3>Campaign</h3>
          <div className='select-wrapper'>
            <Select
              placeholder='Select campaign'
              styles={selectStyles}
              value={
                selectedCampaign
              }
              isDisabled={!availableCampaigns}
              options={campaignsLight}
              onChange={selectCampaignHandler}
              getOptionLabel={(option) => option?.campaignName || option.label}
            />
          </div>
        </div>
        <div className='charts-block flex'>
          <div className='block'>
            <div className='title'>Agent Participation</div>
            <div className='chart chart1 flex'>
              {/* <span>Players completed - 42%</span> */}
              <Chart
                width={'124px'}
                height={'124px'}
                chartType='PieChart'
                loader={<div>Loading Chart</div>}
                data={[
                  ['People', 'Count'],
                  [
                    'Players not completed',
                    agentParticipation
                      ? agentParticipation?.playersAdded -
                        agentParticipation?.playersCompleted
                      : 1,
                  ],
                  [
                    'Players completed',
                    agentParticipation
                      ? agentParticipation?.playersCompleted
                      : 0,
                  ],
                ]}
                options={{
                  tooltip: { trigger: 'none' },
                  legend: 'none',
                  chartArea: { left: 12, top: 12, right: 12, bottom: 12 },
                  slices: {
                    0: { color: '#B31C9D' },
                    1: { color: '#1399A9' },
                  },
                }}
              />
            </div>
            <div className='info flex'>
              <div className='blue block'>
                <span className='square'></span>
                <span>Players not completed</span>
              </div>
              <div className='green block'>
                <span className='square'></span>
                <span>Players completed</span>
              </div>
            </div>
          </div>
          <div className='block'>
            <div className='title'>Average Quiz Score</div>
            <div className='chart chart2 flex'>
              {/* <span>8/10 - 80%</span> */}
              <Chart
                width={'124px'}
                height={'124px'}
                chartType='PieChart'
                loader={<div>Loading Chart</div>}
                data={[
                  ['Quiz', 'Score'],
                  [
                    'Correct',
                    averageQuizScore && averageQuizScore.length > 0
                      ? averageQuizScore.find(
                          ({ answerType }) => answerType === 'Correct'
                        )?.answerPercentage
                      : 0,
                  ],
                  [
                    'Incorrect',
                    averageQuizScore && averageQuizScore.length > 0
                      ? averageQuizScore.find(
                          ({ answerType }) => answerType === 'Incorrect'
                        )?.answerPercentage
                      : 100,
                  ],
                ]}
                options={{
                  tooltip: { trigger: 'none' },
                  legend: 'none',
                  chartArea: { left: 12, top: 12, right: 12, bottom: 12 },
                  slices: {
                    0: { color: '#B31C9D' },
                    1: { color: '#1399A9' },
                  },
                }}
              />
            </div>
            <div className='info flex'>
              <div className='blue block'>
                <span className='square'></span>
                <span>Correct</span>
              </div>
              <div className='orange block'>
                <span className='square'></span>
                <span>Incorrect</span>
              </div>
            </div>
          </div>
        </div>
        <div className='report-info'>
          <div className='report-title'>
            <button
              type='button'
              className={reportType === 'questions' ? 'item active' : 'item'}
              onClick={() => setReportType('questions')}>
              Questions Report
            </button>
            <button
              type='button'
              className={
                reportType === 'users-questions' ? 'item active' : 'item'
              }
              onClick={() => setReportType('users-questions')}>
              Users-Questions report
            </button>
            <button
              type='button'
              className={reportType === 'users-points' ? 'item active' : 'item'}
              onClick={() => setReportType('users-points')}>
              Users-Points & Prizes report
            </button>

            <button
              className='download-report'
              onClick={() => dispatch(downloadReport(campaignId))}>
              Download Reports
            </button>
          </div>
          <div className='table-report'> 
            {reportType === 'questions' && quizQuestionOverviewDetails && (
              <table>
                <thead>
                  <tr>
                    <th>
                      <span>Question</span>
                    </th>
                    <th>
                      <span>Users Answered</span>
                    </th>
                    <th>
                      <span>Users Answered Correct</span>
                    </th>
                    <th>
                      <span>Users Answered Incorrect</span>
                    </th>
                    <th>
                      <span></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {quizQuestionOverviewDetails.length > 0 ? (
                    quizQuestionOverviewDetails.map((question, index) => (
                      <tr key={index}>
                        <td>{question.questionText || ''}</td>
                        <td>
                          {`${
                            question?.correctCount + question?.incorrectCount
                          }`}
                        </td>
                        <td>
                          {`${question?.correctCount || 0} (${
                            question?.correctPercentage || 0
                          }%)`}
                        </td>
                        <td>
                          {`${question?.incorrectCount || 0} (${
                            question?.incorrectPercentage || 0
                          }%)`}
                        </td>
                        <td>
                          <button
                            type='button'
                            className='view-more'
                            onClick={() => setQuestionsReportModal(question)}>
                            View All Users
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan='5' align='center'>
                        There is no information yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}

            {reportType === 'users-questions' && quizUserOverview && (
              <table>
                <thead>
                  <tr>
                    <th>
                      <span>User Name</span>
                    </th>
                    <th>
                      <span>Questions Answered</span>
                    </th>
                    <th>
                      <span>Questions Answered Correct</span>
                    </th>
                    <th colSpan='2'>
                      <span>Questions Answered Incorrect</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {quizUserOverview.length > 0 ? (
                    quizUserOverview.map((user, index) => (
                      <tr key={index}>
                        <td>{user.userName || ''}</td>
                        <td>{user.questionsAnswered || 0}</td>
                        <td>
                          {`${user.correctAnswerCount || 0} (${Math.round(
                            (100 * user.correctAnswerCount) /
                              user.questionsAnswered
                          )}%)`}
                        </td>
                        <td>
                          {`${user.incorrectAnswerCount || 0} (${Math.round(
                            (100 * user.incorrectAnswerCount) /
                              user.questionsAnswered
                          )}%)`}
                        </td>
                        <td>
                          <button
                            type='button'
                            className='view-more'
                            onClick={() => setUsersReportModal(user)}>
                            View All Questions
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan='5' align='center'>
                        There is no information yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}

            {reportType === 'users-points' && userPointsPrizesOverview && (
              <table>
                <thead>
                  <tr>
                    <th className='transparent' />
                    <th colSpan='4'>
                      <span>Spins</span>
                    </th>
                    <th className='transparent' />
                    <th colSpan='2'>
                      <span>Prizes</span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <span>User Name</span>
                    </th>
                    <th>
                      <span>Earned</span>
                    </th>
                    <th>
                      <span>Used</span>
                    </th>
                    <th>
                      <span>Used/PYL</span>
                    </th>
                    <th>
                      <span>Left</span>
                    </th>
                    <th>
                      <span>Points</span>
                    </th>
                    <th>
                      <span>Prize</span>
                    </th>
                    <th>
                      <span>PYL</span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {userPointsPrizesOverview.length > 0 ? (
                    userPointsPrizesOverview.map((user, index) => (
                      <tr key={index}>
                        <td>{user.userName}</td>
                        <td>{user.totalSpinsEarned}</td>
                        <td>{user.totalSpinsEarned - user.remainingSpins}</td>
                        <td>{user.isPressYourLuckSpinUsed}</td>
                        <td>{user.remainingSpins}</td>
                        <td>{user.totalPointsEarned}</td>
                        <td>{user.awardedPrize || 'none'}</td>
                        <td>{user.isPressYourLuckPrize ? 'yes' : 'no'}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan='8' align='center'>
                        There is no information yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <Dialog open={questionsReportModal !== null} style={{ transition: 'none' }}>
        <div className='questions-report-dialog'>
          <button
            type='button'
            className='close-dialog'
            onClick={() => setQuestionsReportModal(null)}>
            <ClearIcon style={{ fontSize: 'inherit' }} />
          </button>
          <h3 className='questions-report-dialog-title'>Question:</h3>
          <p>{questionsReportModal?.questionText}</p>

          <div className='table-report'>
            <table>
              <thead>
                <tr>
                  <th>
                    <span>User Name</span>
                  </th>
                  <th>
                    <span>Correct</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {questionsReportModal?.correctAnswerUsers.length > 0 &&
                  questionsReportModal?.correctAnswerUsers
                    .split(', ')
                    .map((user, index) => {
                      console.log(user);
                      return (
                        <tr key={index}>
                          <td>{user}</td>
                          <td className='correct'>Yes</td>
                        </tr>
                      );
                    })}
                {questionsReportModal?.incorrectAnswerUsers.length > 0 &&
                  questionsReportModal?.incorrectAnswerUsers
                    .split(', ')
                    .map((user, index) => {
                      return (
                        <tr key={index}>
                          <td>{user}</td>
                          <td className='incorrect'>No</td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
            <div className='report-button'>
              <button
                className='download-report'
                onClick={() =>
                  dispatch(
                    downloadQuestionReport(
                      campaignId,
                      questionsReportModal.questionId
                    )
                  )
                }>
                Download Reports
              </button>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog open={usersReportModal !== null}>
        <div className='questions-report-dialog'>
          <button
            type='button'
            className='close-dialog'
            onClick={() => setUsersReportModal(null)}>
            <ClearIcon style={{ fontSize: 'inherit' }} />
          </button>
          <h3 className='questions-report-dialog-title'>
            User Name: <span>{usersReportModal?.userName}</span>
          </h3>

          <div className='table-report'>
            <table>
              <thead>
                <tr>
                  <th>
                    <span>Question</span>
                  </th>
                  <th>
                    <span>Correct</span>
                  </th>
                  <th>
                    <span>Incorrect</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {usersReportModal?.answerOverview
                  .split(', ')
                  .map((user, index) => {
                    const question = user.split(': ')[0];
                    const corrent = user.split(': ')[1];
                    return (
                      <tr key={index}>
                        <td>{question}</td>
                        <td className={corrent === '1' ? 'correct' : ''}>
                          {corrent === '1' ? 'X' : ''}
                        </td>
                        <td className={corrent === '0' ? 'incorrect' : ''}>
                          {corrent === '0' ? 'X' : ''}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className='report-button'>
              <button
                className='download-report'
                onClick={() =>
                  dispatch(
                    downloadUserReport(campaignId, usersReportModal.userId)
                  )
                }>
                Download Reports
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Report;
