const api = {
  token: `/oauth/Token`,
  account: {
    forgotPassword: `/api/Account/ForgotPassword`,
    changePassword: `/api/Account/ChangePassword`,
    user: `/api/Account/user`,
    logout: `/api/Account/Logout`,
  },
  games: {
    details: `/api/GameProgress/GetGameDetailsForUser`,
    progress: `/api/GameProgress/GetUserProgressForCampaign`,
    putGameStatus: ` /api/GameStatus/PutGameStatus`,
    getAvailablePrizes: `/api/GameProgress/GetAvailablePrizesForGame`,
    getGameStatus: `/api/GameProgress/GetGameStatusForCampaign`,
  },
  userAnswers: {
    get: `/api/UserAnswers/GetUserAnswers`,
    post: '/api/UserAnswers/PostUserAnswer',
  },
  clients: {
    get: `/api/Clients`,
    branding: `/api/Clients/GetClientBrandingInformation`,
    update: `/api/Clients/UpdateClientBrandingInfo`,
  },
  сampaigns: {
    available: `/api/Campaigns/GetAvailableCampaigns`,
    availableForClient: `api/Campaigns/GetAvailableCampaignsForClient`,
    post: `/api/Campaigns/PostCampaign`,
    put: `/api/Campaigns/PutCampaign`,
    light: `/api/Campaigns/GetCampaignNamesWithId`,
  },
  campaignTypes: {
    get: `/api/CampaignTypes`,
  },
  themes: `/api/Themes`,
  files: {
    get: `/api/Files/GetFiles`,
    post: `/api/Files/PostFile`,
    put: `/api/Files/PutFile`,
  },
  prizes: {
    bulk: `/api/Prizes/BulkPrizeOperation`,
    awarded: `/api/AwardedPrizes/PostAwardedPrizeForCampaign`,
    get: `/api/Campaigns/GetCampaignPrizes`,
  },
  questions: {
    getAll: '/api/Questions/GetCampaignQuestions',
    get: '/api/Questions/GetQuestions',
    put: '/api/Questions/PutQuestion',
    post: '/api/Questions/PostNewQuestionWithAnswer',
    delete: '/api/Questions/DeleteQuestion',
    deleteAll: '/api/Questions/DeleteCampaignQuestions',
    downloadTemplate: '/api/BulkOperation/GetBulkQuestionInsertTemplate',
    uploadTemplate: '/api/BulkOperation/QuestionBulkUpload',
  },
  users: {
    getAll: `/api/Campaigns/GetCampaignUsers`,
    get: `/api/Account/user`,
    put: `/api/Account/UpdateUser`,
    post: `/api/Account/AddUserToCampaign`,
    delete: `/api/Campaigns/RemoveCampaignUser`,
    deleteAll: `/api/Campaigns/RemoveCampaignUsers`,
    downloadTemplate: `/api/BulkOperation/GetBulkUserUpsertTemplate`,
    uploadTemplate: `/api/BulkOperation/UserBulkUplaod`,
    sendNotificationEmail: `/api/Campaigns/SendNotificationEmailToCampaignUser`,
  },
  reports: {
    getAgentParticipation: `/api/Report/GetAgentParticipation`,
    getAverageQuizScore: `/api/Report/GetAverageQuizScore`,
    getQuizQuestionOverviewDetails: `/api/Report/GetQuizQuestionOverviewDetails`,
    getQuizUserOverview: `/api/Report/GetQuizUserOverview`,
    getUserPointsPrizesOverview: `/api/Report/GetUserPointsPrizesOverview`,
    getMainReportByCampaign: `/api/Report/GetMainReportByCampaign`,
    getReportQuestionUserDetailReport: `/api/Report/GetReportQuestionUserDetailReport`,
    getReportUserQuestionAnswerDetailReport: `/api/Report/GetReportUserQuestionAnswerDetailReport`,
  },
};

export default api;
