import React from 'react';
import { Link, useParams } from 'react-router-dom';

import CheckIcon from '@material-ui/icons/Check';
import ExtensionIcon from '@material-ui/icons/Extension';
import GroupIcon from '@material-ui/icons/Group';
import AssignmentIcon from '@material-ui/icons/Assignment';
import brandingImg from './assets/noun_Fingerprint.png';
import './Steps.scss';

const Steps = ({ activeStep }) => {
  const { userId } = useParams();

  return (
    <div className='admin-steps flex align-center justify-between'>
      <div className='left flex align-center'>
        <div
          className={`item ${activeStep === 'branding' ? 'active' : ''} ${
            activeStep !== 'branding' ? 'completed' : ''
          }`}>
          <Link to={`/user/${userId}/branding`}>
            <span className='img'>
              <img src={brandingImg} alt='brandingImg' />
            </span>
            <span className='name'>
              <span className='completed'>
                <CheckIcon fontSize='inherit' />
              </span>
              Branding
            </span>
          </Link>
        </div>
        <div
          className={`item ${activeStep === 'game-setup' ? 'active' : ''} ${
            activeStep !== 'branding' && activeStep !== 'game-setup'
              ? 'completed'
              : ''
          }`}>
          <Link to={`/user/${userId}/game-setup`}>
            <span className='img'>
              <ExtensionIcon fontSize='inherit' />
            </span>
            <span className='name'>
              <span className='completed'>
                <CheckIcon fontSize='inherit' />
              </span>
              Game Setup
            </span>
          </Link>
        </div>
        <div
          className={`item ${activeStep === 'questions' ? 'active' : ''} ${
            activeStep !== 'branding' &&
            activeStep !== 'game-setup' &&
            activeStep !== 'questions'
              ? 'completed'
              : ''
          }`}>
          <span className='img'>
            <i className='icon'>?</i>
          </span>
          <span className='name'>
            <span className='completed'>
              <CheckIcon fontSize='inherit' />
            </span>
            Questions
          </span>
        </div>
        <div
          className={`item ${activeStep === 'users' ? 'active' : ''} ${
            activeStep !== 'branding' &&
            activeStep !== 'game-setup' &&
            activeStep !== 'questions' &&
            activeStep !== 'users'
              ? 'completed'
              : ''
          }`}>
          <span className='img'>
            <GroupIcon fontSize='inherit' />
          </span>
          <span className='name'>
            <span className='completed'>
              <CheckIcon fontSize='inherit' />
            </span>
            Users
          </span>
        </div>
      </div>
      <div className='right'>
        <div
          className={activeStep === 'report' ? 'item active' : 'item active'}>
          <Link to={`/user/${userId}/report`}>
            <span className='img'>
              <AssignmentIcon fontSize='inherit' />
            </span>
            <span className='name'>Report</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Steps;
