import React from 'react';
import './Header.scss';
import BrandLogo from 'containers/User/BrandLogo';

const Header = ({ user, toggleResetPassDialog, toggleLogoutDialog }) => {
  return (
    <div className='admin-header'>
      <div className='links'>
        <span className='name'>Hello Admin</span>
        <button
          className='change-pass'
          onClick={() => toggleResetPassDialog(true)}>
          Change Password
        </button>
        <button className='log-off' onClick={() => toggleLogoutDialog(true)}>
          Log Off
        </button>
      </div>
      <div className='logo'>
        <BrandLogo />
      </div>
    </div>
  );
};

export default Header;
