const styles = theme => ({
  progressBar: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 9999,
  }
});

export default styles;