import axios from 'axios';
import { api } from 'settings';

export const FETCH_GAME_DETAILS = 'FETCH_GAME_DETAILS';
export const FETCH_GAME_PROGRESS = 'FETCH_GAME_PROGRESS';
export const FETCH_GAME_STATUS = 'FETCH_GAME_STATUS';
export const UPDATE_GAME_STATUS = 'UPDATE_GAME_STATUS';
export const FETCH_AVAILABLE_PRIZES = 'FETCH_AVAILABLE_PRIZES';
export const SELECT_CAMPAIGN = 'SELECT_CAMPAIGN';
export const CLEAR_GAME_DETAILS = 'CLEAR_GAME_DETAILS';

export const fetchGameDetails = (userId) => (dispatch) => {
  dispatch({
    type: CLEAR_GAME_DETAILS,
  });
  return axios
    .get(`${api.games.details}`, { params: { userId } })
    .then((response) => {
      dispatch({
        type: FETCH_GAME_DETAILS,
        payload: response.data,
      });

      return response.data;
    });
};

export const fetchGameProgress = (params) => (dispatch) => {
  return axios.get(`${api.games.progress}`, { params }).then((response) => {
    dispatch({
      type: FETCH_GAME_PROGRESS,
      payload: response.data,
    });
    return response.data;
  });
};

export const updateGameStatus = (params, data) => (dispatch) => {
  return axios
    .put(`${api.games.putGameStatus}`, data, { params })
    .then((response) => {
      dispatch({
        type: FETCH_GAME_STATUS,
        payload: response.data,
      });

      return response.data;
    });
};
export const fetchGameStatus = (params) => (dispatch) => {
  return axios
    .get(`${api.games.getGameStatus}`, { params })
    .then((response) => {
      dispatch({
        type: FETCH_GAME_STATUS,
        payload: response.data,
      });

      return response.data;
    });
};

export const fetchAvailablePrizes = (campaignId) => (dispatch) => {
  return axios
    .get(`${api.games.getAvailablePrizes}/${campaignId}`)
    .then((response) => {
      dispatch({
        type: FETCH_AVAILABLE_PRIZES,
        payload: response.data,
      });
      return response.data;
    });
};

export const selectCampaign = (campaign) => (dispatch) =>
  dispatch({
    type: SELECT_CAMPAIGN,
    payload: campaign,
  });
