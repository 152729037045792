import { FETCH_USERS, DELETE_USERS, CLEAR_USERS } from './actions';
const initialState = {
  data: null,
};
export const users = (state = initialState, { type, payload }) => {
  switch (type) {
    case CLEAR_USERS: {
      return {
        ...state,
        data: null,
      };
    }
    case FETCH_USERS: {
      return {
        ...state,
        data: payload,
      };
    }
    case DELETE_USERS: {
      return {
        ...state,
        data: null,
      };
    }

    default:
      return state;
  }
};
