import { FETCH_QUESTIONS, DELETE_QUESTIONS, CLEAR_QUESTIONS } from './actions';
const initialState = {
  data: null,
};
export const questions = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_QUESTIONS: {
      return {
        ...state,
        data: payload,
      };
    }
    case CLEAR_QUESTIONS: {
      return {
        ...state,
        data: null,
      };
    }
    case DELETE_QUESTIONS: {
      return {
        ...state,
        data: null,
      };
    }

    default:
      return state;
  }
};
