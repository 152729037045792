import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Admin from 'containers/Admin';
import Game from 'containers/Game';
import ChangePassword from './ChangePassword';
import {
  fetchUser,
  logout,
  toggleLogoutDialog,
} from 'store/authentication/actions';
import './User.scss';

let User = (props) => {
  const { userId } = props.match.params;
  const [isAdmin, setIsAdmin] = useState(undefined);
  const [isPasswordChangeEnforced, setPasswordChangeEnforced] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const { isAuthenticated, user, quitDialog, resetPassDialog } = useSelector(
    (state) => ({
      isAuthenticated: state.authentication.isAuthenticated,
      user: state.authentication.user,
      quitDialog: state.authentication.quitDialog,
      resetPassDialog: state.authentication.resetPassDialog,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isAuthenticated && userId) {
      dispatch(fetchUser(userId)).then((user) => {
        setIsAdmin(user.roles.includes('Admin'));
        setPasswordChangeEnforced(user.isPasswordChangeEnforced);
      });
    }
  }, [dispatch, isAuthenticated, userId]);

  const handleLogout = () => {
    dispatch(logout()).then(() => {
      history.push('/login');
    });
  };

  if (!user || !isAuthenticated || isAdmin === undefined) return null;

  return (
    <>
      {isAdmin ? (
        <Admin isAuthenticated={isAuthenticated} user={user} />
      ) : (
        <Game isAuthenticated={isAuthenticated} user={user} />
      )}

      <Dialog open={quitDialog}>
        <div className='quit-dialog'>
          <h3 className='quit-dialog-title2'>Are you sure you want to quit?</h3>

          <div className='quit-dialog-buttons'>
            <button onClick={handleLogout}>Yes quit</button>
            <button onClick={() => dispatch(toggleLogoutDialog(false))}>
              No continue
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog open={resetPassDialog || isPasswordChangeEnforced}>
        <ChangePassword
          user={user}
          setPasswordChangeEnforced={setPasswordChangeEnforced}
        />
      </Dialog>
    </>
  );
};

export default User;
