import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

export class Loader extends Component {
  render() {
    return (
      <Grid container direction='column' justify='center' alignItems='center'>
        <Grid item xs={12}>
          <CircularProgress {...this.props} />
        </Grid>
      </Grid>
    );
  }
}

export default Loader;
