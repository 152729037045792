import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CustomRadio from 'components/CustomRadio';
import InputFile from 'components/InputFile';

import './Branding.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addFile, updateFile } from 'store/files/actions';
import { updateClient } from 'store/clients/actions';
import { setTheme } from 'store/global/actions';
import Buttons from '../Buttons';

let Branding = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [themeId, setThemeId] = useState('');
  const [file, setFile] = useState(null);
  const [pending, setPending] = useState(false);
  const { userId } = useParams();
  const [pristine, setPristine] = useState(true);

  const { client, themes } = useSelector((state) => ({
    client: state.clients.client,
    themes: state.global.themes,
  }));

  const handleSave = useCallback(
    (button) => {
      if (pristine && button === 'next') {
        return history.push(`/user/${userId}/game-setup`);
      }
      setPending(true);

      let filePromise;

      if (file) {
        const handler = file.id ? updateFile : addFile;

        if (!pristine) {
          filePromise = dispatch(handler(file));
        }
      } else {
        filePromise = Promise.resolve();
      }

      return filePromise
        .then((response) => {
          const data = {
            clientId: client.id,
            themeId: themeId,
          };
          if (response) {
            data.logoId = response.id || client.logoId;
          }

          return dispatch(updateClient(data));
        })
        .then((response) => {
          localStorage.setItem(
            'activeTheme',
            response.theme?.description?.toLowerCase() || 'full'
          );
          setPending(false);
          setPristine(true);
          if (button === 'next') {
            history.push(`/user/${userId}/game-setup`);
          }
        });
    },
    [file, dispatch, client, themeId, history, userId, pristine]
  );

  useEffect(() => {
    if (client) {
      setThemeId(client?.theme?.id);
      setFile(client?.logo);
    }
  }, [client]);

  return (
    <div className='steps-content branding-content'>
      <div className='row-block flex justify-between'>
        <div className='block w-1'></div>
        <div className='block w-3'>
          <span className='inner-title'>Upload Vendor logo for Quiz game</span>
          <div className='inner-wrap'>
            <div className='choose-img'>
              <InputFile
                file={file}
                setFile={(file) => {
                  setFile(file);
                  setPristine(false);
                }}
                accept='.png, .jpg, .jpeg'
                disabled={!client}
              />
            </div>
          </div>
        </div>
        <div className='block w-3'>
          <span className='inner-title'>Theme Color</span>
          <div className='inner-wrap'>
            <div className='choose-theme'>
              {themes &&
                themes.map((e) => (
                  <CustomRadio
                    key={e.id}
                    label={`Theme ${e.description}`}
                    value={e.id}
                    checked={themeId}
                    onChange={(id) => {
                      setThemeId(id);
                      setPristine(false);
                      dispatch(setTheme(e?.description || 'full'));
                    }}
                    disabled={!client || pending}
                    img={e.themeImage.url}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>

      <Buttons
        onSave={() => handleSave('save')}
        onNext={() => handleSave('next')}
        disabled={!client || pending}
      />
    </div>
  );
};

export default Branding;
