import React, { useRef } from 'react';
import './InputFile.scss';

const InputFile = ({ file, setFile, accept = '*.*', disabled = false }) => {
  const ref = useRef();

  const handleChange = (event) => {
    let newFile = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(newFile);
    reader.onload = () => {
      const fileInfo = {
        id: file?.id,
        fileName: newFile.name,
        mimeType: newFile.type,
        base64String: reader.result.split(',')[1],
        isDeleted: false,
      };
      setFile(fileInfo);
    };
  };

  return (
    <div className='upload-file'>
      <form>
        <label onClick={() => ref && ref.current.click()}>Browse</label>
        <input
          type='file'
          id='uploadButton'
          style={{ display: 'none' }}
          onChange={handleChange}
          accept={accept}
          disabled={disabled}
          ref={ref}
        />
        <div className='upload-img-block'>
          {file && (
            <img
              src={
                file?.base64String
                  ? `data:${file.mimeType};base64,${file.base64String}`
                  : file?.url
              }
              alt=''
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default InputFile;
