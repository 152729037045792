import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import QuizEndDialog from './QuizEndDialog';
import { logout, toggleLogoutDialog } from 'store/authentication/actions';
import { updateGameStatus } from 'store/games/actions';
import {
  getActiveCanpaign,
  getQuestions,
  getQuestionIndex,
  getEarnedSpins,
} from 'store/games/selectors';
import { postUserAnswer } from 'store/userAnswers/actions';

import './style.scss';
import BrandLogo from 'containers/User/BrandLogo';

class Quiz extends PureComponent {
  state = {
    questionIndex: null,
    spinsEarned: 0,
    selectedAnswer: null,
    isSubmitted: false,
    quizEndDialog: false,
    getActiveCanpaign,
  };

  componentDidMount() {
    const { user, history } = this.props;
    if (!user) {
      history.push('/login');
    }
  }

  _chooseAnswer = (question, answer) => {
    const { isSubmitted } = this.state;

    if (!isSubmitted) {
      this.setState({
        selectedAnswer: answer,
      });
    }
  };

  _nextQuestion = () => {
    const { questions, storedQuestionIndex } = this.props;
    const { questionIndex } = this.state;

    const activeQuestionIndex = questionIndex || storedQuestionIndex;

    const newIndex = activeQuestionIndex + 1;
    const newState = {};

    const { spinsEarned } = this.state;

    const {gameStatus, updateGameStatus} =
      this.props;
    const newGameStatus = {
      ...gameStatus,
      totalSpinsEarned: spinsEarned,
      remainingSpins: spinsEarned,
      totalPointsEarned: 0,
    };

    // if (!activeCanpaign.campaignType.isSmartSpinAllowed) {
    //   newGameStatus.gameStatusType = 1;
    // }
    
    if (newIndex === questions.length) {
      if (spinsEarned === 0) {
        newGameStatus.gameStatusType = 1;
        updateGameStatus({ id: gameStatus.id }, newGameStatus)
      }
      newState.quizEndDialog = true;
      newState.selectedAnswer = null;
      newState.isSubmitted = false;
    } else {
      newState.selectedAnswer = null;
      newState.questionIndex = newIndex;
    }
    this.setState(newState);
  };

  _sumbitAnswer = (question, isCorrect) => {
    const {
      activeCanpaign,
      postUserAnswer,
      storedEarnedSpins,
      updateGameStatus,
      gameStatus,
      match: {
        params: { userId },
      },
    } = this.props;

    const { spinsEarned, selectedAnswer } = this.state;

    const activeEarnedSpins = spinsEarned || storedEarnedSpins;

    this.setState({
      isSubmitted: true,
      spinsEarned: isCorrect ? activeEarnedSpins + 1 : activeEarnedSpins,
    });

    const spinsEarnedNew = isCorrect ? activeEarnedSpins + 1 : activeEarnedSpins;
    
    const newGameStatus = {
      ...gameStatus,
      totalSpinsEarned: spinsEarnedNew,
      remainingSpins: spinsEarnedNew,
      totalPointsEarned: 0,
    };
    updateGameStatus({ id: newGameStatus.id }, newGameStatus)

    setTimeout(
      () => {
        this._nextQuestion();
        this.setState({ isSubmitted: false });
      },
      isCorrect ? 2000 : 5000
    );

    postUserAnswer({
      userId,
      questionId: question.id,
      answerId: selectedAnswer.id,
      isCorrect: isCorrect,
      campaignId: activeCanpaign.id,
    });
  };

  _onSmartSpin = (activeEarnedSpins) => {
    const {
      history,
      updateGameStatus,
      setUpdater,
      gameStatus,
      match: {
        params: { userId, campaignId },
      },
    } = this.props;

    const newGameStatus = {
      ...gameStatus,
      totalSpinsEarned: activeEarnedSpins,
      remainingSpins: activeEarnedSpins,
      totalPointsEarned: 0,
    };

    updateGameStatus({ id: newGameStatus.id }, newGameStatus).then(() => {
      setUpdater(new Date());
      history.push(`/user/${userId}/spin/${campaignId}`);
    });
    console.log(gameStatus);
  };


  onLogout = (activeEarnedSpins) => {
    const { logout, history, gameStatus, updateGameStatus, activeCanpaign } =
      this.props;
    const newGameStatus = {
      ...gameStatus,
      totalSpinsEarned: activeEarnedSpins,
      remainingSpins: activeEarnedSpins,
      totalPointsEarned: 0,
    };

    // if (!activeCanpaign.campaignType.isSmartSpinAllowed) {
    //   newGameStatus.gameStatusType = 1;
    // }

    // if (activeEarnedSpins === 0 || !activeCanpaign.campaignType.isSmartSpinAllowed) {
    //   newGameStatus.gameStatusType = 1;
    // }
    // updateGameStatus({ id: gameStatus.id }, newGameStatus)
    //   .then(() => logout())
    //   .then(() => {
    //     history.push(
    //       activeCanpaign?.campaignType?.isSmartSpinAllowed
    //         ? '/login?thankyou=true'
    //         : '/login?thankyou=true'
    //     );
    //   });
    logout().then(() => {
      history.push(
        activeCanpaign?.campaignType?.isSmartSpinAllowed
          ? '/login?thankyou=true'
          : '/login?thankyou=true'
      );
    });
  };

  render() {
    const {
      user,
      activeCanpaign,
      questions,
      toggleLogoutDialog,
      storedQuestionIndex,
      storedEarnedSpins,
    } = this.props;
    const {
      spinsEarned,
      selectedAnswer,
      isSubmitted,
      questionIndex,
      quizEndDialog,
    } = this.state;

    const activeQuestionIndex = questionIndex || storedQuestionIndex;
    const activeEarnedSpins = spinsEarned || storedEarnedSpins;

    if (!user || !activeCanpaign || !questions) return null;

    const question = questions[activeQuestionIndex];

    const answers = questions[activeQuestionIndex]
      ? questions[activeQuestionIndex].answers
      : [];

    return (
      <>
        {questions && activeQuestionIndex < questions.length && (
          <div className='quiz'>
            <div className='inner'>
              <div className='user-content'>
                <div className='name'>
                  <h2>{user.fullName}</h2>
                </div>
                <div className='logo'>
                  <BrandLogo />
                </div>
              </div>
              <div className='quiz-content'>
                <span className='quantity'>
                  {`${activeQuestionIndex + 1} of ${
                    activeCanpaign.questions.length
                  }`}
                </span>
                <div className='question-row'>
                  {user.managerAvatar && (
                    <div className='manager-avatar'>
                      <img
                        src={user?.managerAvatar?.url}
                        alt={user?.managerAvatar?.description || ''}
                      />
                    </div>
                  )}
                  <div className='question-text'> {question.questionText}</div>
                </div>

                <div className='question-answer'>
                  {answers.map((answer, index) => (
                    <div
                      key={answer.id}
                      className={`item flex ${
                        selectedAnswer?.id === answer.id ? 'selected' : ''
                      } ${
                        isSubmitted
                          ? answer.id === selectedAnswer?.id
                            ? answer.id === question.correctAnswer.id
                              ? 'correct'
                              : 'error'
                            : ''
                          : ''
                      }`}
                      onClick={() => this._chooseAnswer(question, answer)}>
                      <div className='item-choose'>
                        <span>{String.fromCharCode(index + 65)}</span>
                      </div>
                      <div className='item-text'>{answer.answerText}</div>
                    </div>
                  ))}
                  {!isSubmitted ? (
                    <button
                      className='gray-btn'
                      disabled={!selectedAnswer}
                      onClick={() =>
                        this._sumbitAnswer(
                          question,
                          selectedAnswer.id === question.correctAnswer.id
                        )
                      }>
                      Submit
                    </button>
                  ) : (
                    <div className='correct-answer-text'>
                      {selectedAnswer?.id !== question?.correctAnswer?.id && (
                        <div className='correct-answer-text-inner'>
                          <span>
                            <b>Correct Answer: </b>
                            {question.correctAnswer.answerText}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className='bottom-content flex justify-between align-center'>
                <div className='earned-block'>
                  <span>
                    {activeCanpaign.campaignType.isSmartSpinAllowed
                      ? 'Spins'
                      : 'Points'}{' '}
                    Earned <span className='quantity'>{activeEarnedSpins}</span>
                  </span>
                </div>
                <button onClick={() => toggleLogoutDialog(true)}>Quit</button>
              </div>
            </div>
          </div>
        )}
        <QuizEndDialog
          isOpened={quizEndDialog || activeQuestionIndex === questions.length}
          points={activeEarnedSpins}
          isContinue={activeCanpaign.campaignType.isSmartSpinAllowed}
          onSmartSpin={() => this._onSmartSpin(activeEarnedSpins)}
          onLogout={() => this.onLogout(activeEarnedSpins)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  activeCanpaign: getActiveCanpaign(state.games),
  questions: getQuestions(state.games),
  storedQuestionIndex: getQuestionIndex(state.games),
  storedEarnedSpins: getEarnedSpins(state.games),
  gameStatus: state.games.status,
});

const mapDispatchToProps = {
  logout,
  toggleLogoutDialog,
  postUserAnswer,
  updateGameStatus,
};

Quiz = connect(mapStateToProps, mapDispatchToProps)(Quiz);
Quiz = withRouter(Quiz);
export default Quiz;
