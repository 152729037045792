import axios from 'axios';
import { api } from 'settings';

export const FETCH_AVAILABLE_CAMPAIGNS = 'FETCH_AVAILABLE_CAMPAIGNS';
export const FETCH_AVAILABLE_CAMPAIGNS_FOR_CLIENT =
  'FETCH_AVAILABLE_CAMPAIGNS_FOR_CLIENT';
export const FETCH_CAMPAIGNS_LIGHT = 'FETCH_CAMPAIGNS_LIGHT';
export const FETCH_CAMPAIGN_TYPES = 'FETCH_CAMPAIGN_TYPES';
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';

export const fetchCampaignsLight = (clientId) => (dispatch) => {
  return axios.get(`${api.сampaigns.light}/${clientId}`).then((response) => {
    dispatch({
      type: FETCH_CAMPAIGNS_LIGHT,
      payload: response.data,
    });
    return response.data;
  });
};

export const fetchAvailableCampaigns = (clientId) => (dispatch) => {
  return axios
    .get(`${api.сampaigns.available}/${clientId}`)
    .then((response) => {
      dispatch({
        type: FETCH_AVAILABLE_CAMPAIGNS,
        payload: response.data,
      });
      return response.data;
    });
};

export const fetchAvailableCampaignsForClient = (clientId) => (dispatch) => {
  return axios
    .get(`${api.сampaigns.availableForClient}/${clientId}`)
    .then((response) => {
      dispatch({
        type: FETCH_AVAILABLE_CAMPAIGNS_FOR_CLIENT,
        payload: response.data,
      });
      return response.data;
    });
};

export const fetchCampaignTypes = () => (dispatch) => {
  return axios.get(`${api.campaignTypes.get}`).then((response) => {
    dispatch({
      type: FETCH_CAMPAIGN_TYPES,
      payload: response.data,
    });
    return response.data;
  });
};

export const createCampaign = (data, clientId) => (dispatch) => {
  return axios
    .post(`${api.сampaigns.post}/${clientId}`, data)
    .then((response) => {
      dispatch({
        type: CREATE_CAMPAIGN,
        payload: response.data,
      });
      return response.data;
    });
};
export const updateCampaign = (data) => (dispatch) => {
  return axios
    .put(`${api.сampaigns.put}`, data, { params: { id: data.id } })
    .then((response) => {
      dispatch({
        type: UPDATE_CAMPAIGN,
        payload: response.data,
      });
      return response.data;
    });
};
