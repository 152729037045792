import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const PageLoader = ({ requestQueue, classes }) => {
  return (
    <div className={classes.progressBar}>
      {requestQueue.length > 0 && (
        <LinearProgress variant='query' color='secondary' />
      )}
    </div>
  );
};

export default withStyles(styles)(PageLoader);
