import React from 'react';
import ReactDOM from 'react-dom';
import Application from './containers/Application';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider as StoreProvider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import store from 'store';
import 'settings/http';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

class ExtendedBrowserRouter extends BrowserRouter {
  history;
}

ReactDOM.render(
  <StoreProvider store={store}>
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      maxSnack={3}>
      <ExtendedBrowserRouter basename={process.env.REACT_APP_BASE_URL || '/'}>
        <Application />
      </ExtendedBrowserRouter>
    </SnackbarProvider>
  </StoreProvider>,

  document.getElementById('root')
);

reportWebVitals();
