import { FETCH_FILES } from './actions';
const initialState = {
  files: null,
};
export const files = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_FILES: {
      return {
        ...state,
        files: payload,
      };
    }

    default:
      return state;
  }
};
