import React, { PureComponent } from 'react';
import Winwheel from './Winwheel.js';
import './style.scss';
import arrow from './assets/arrow.png';
import soundfile from './assets/example.mp3';
import s1 from './assets/s1.png';
import s2 from './assets/s2.png';
import s3 from './assets/s3.png';
import s4 from './assets/s4.png';
import s5 from './assets/s5.png';
import s6 from './assets/s6.png';
import s7 from './assets/s7.png';
import s8 from './assets/s8.png';
import s9 from './assets/s9.png';
import s10 from './assets/s10.png';
import s11 from './assets/s11.png';
import s13 from './assets/s13.png';
import s14 from './assets/s14.png';
import s15 from './assets/s15.png';
import s16 from './assets/s16.png';
import s17 from './assets/s17.png';
import s18 from './assets/s18.png';
import s19 from './assets/s19.png';
import s20 from './assets/s20.png';
import s21 from './assets/s21.png';
import s22 from './assets/s22.png';
import s23 from './assets/s23.png';
import s24 from './assets/s24.png';
import press from './assets/press.png';
import logo from './assets/logo.png';

const createImage = (src, alt, title) => {
  var img = true ? new Image() : document.createElement('img');
  img.src = src;
  if (alt != null) img.alt = alt;
  if (title != null) img.title = title;

  img.height = 290;
  return img;
};

class WheelOfFortune extends PureComponent {
  state = {
    theWheel: null,
    ready: true,
  };

  audio = new Audio(soundfile);

  componentDidMount() {
    const { isPressYourLuckAllowed = false, bonusPointsAwarded = 0 } =
      this.props;

    // const theWheel = new Winwheel({
    //   drawText: true,
    //   pointerAngle: 43,
    //   responsive: true,
    //   canvasId: 'wheelOfFortune',
    //   strokeStyle: 0,
    //   fillStyle: '#ffffff',
    //   lineWidth: 0,
    //   innerRadius: 50,
    //   textOrientation: 'curved',
    //   textAlignment: 'outer',
    //   textFontFamily: 'Poppins',
    //   textFontSize: '29',
    //   numSegments: 48,
    //   segments: [
    //     { fillStyle: '#2783a6', size: 2, value: 0 },
    //     { fillStyle: '#3ac1f4', text: '0', value: 0 },
    //     { fillStyle: '#166691', size: 2, value: 55 },
    //     { fillStyle: '#2097d5', text: '55', value: 55 },
    //     { fillStyle: '#23518a', size: 2, value: 15 },
    //     { fillStyle: '#3377cb', text: '15', value: 15 },
    //     { fillStyle: '#2f3280', size: 2, value: 65 },
    //     { fillStyle: '#4549bc', text: '65', value: 65 },
    //     { fillStyle: '#4d3487', size: 2, value: 70 },
    //     { fillStyle: '#724cc7', text: '70', value: 70 },
    //     { fillStyle: '#4d3487', size: 2, value: 30 },
    //     { fillStyle: '#8839bd', text: '30', value: 30 },
    //     { fillStyle: '#6b2173', size: 2, value: 80 },
    //     { fillStyle: '#9d31aa', text: '80', value: 80 },
    //     { fillStyle: '#86177e', size: 2, value: 25 },
    //     { fillStyle: '#c622b9', text: '25', value: 25 },
    //     { fillStyle: '#971273', size: 2, value: 85 },
    //     { fillStyle: '#de1baa', text: '85', value: 85 },
    //     { fillStyle: '#941645', size: 2, value: 40 },
    //     { fillStyle: '#da2066', text: '40', value: 40 },
    //     { fillStyle: '#6b233e', size: 2, value: 5 },
    //     { fillStyle: '#9d345c', text: '5', value: 5 },
    //     { fillStyle: '#323032', size: 2, value: 100 },
    //     { fillStyle: '#4a474a', text: '100', value: 100 },
    //     { fillStyle: '#841527', size: 2, value: 0 },
    //     { fillStyle: '#c21f3a', text: '0', value: 0 },
    //     { fillStyle: '#9d173b', size: 2, value: 95 },
    //     { fillStyle: '#e82257', text: '95', value: 95 },
    //     { fillStyle: '#a14b29', size: 2, value: 10 },
    //     { fillStyle: '#ee6f3d', text: '10', value: 10 },
    //     { fillStyle: '#a6932d', size: 2, value: 60 },
    //     { fillStyle: '#f5d943', text: '60', value: 60 },
    //     { fillStyle: '#aaad37', size: 2, value: 20 },
    //     { fillStyle: '#faff51', text: '20', value: 20 },
    //   ]
    //     .concat(
    //       isPressYourLuckAllowed
    //         ? [
    //             { fillStyle: '#73aa39', size: 2, value: 'pressYourLuck' },
    //             {
    //               fillStyle: '#a9fb54',
    //               text: 'Press',
    //               value: 'pressYourLuck',
    //               textOrientation: 'vertical',
    //             },
    //           ]
    //         : [
    //             { fillStyle: '#73aa39', size: 2, value: 0 },
    //             { fillStyle: '#a9fb54', text: '0', value: 0 },
    //           ]
    //     )
    //     .concat([
    //       { fillStyle: '#33a349', size: 2, value: 50 },
    //       { fillStyle: '#4bf06b', text: '50', value: 50 },
    //       { fillStyle: '#29824e', size: 2, value: 75 },
    //       { fillStyle: '#3dbf73', text: '75', value: 75 },
    //       { fillStyle: '#0e6030', size: 2, value: 35 },
    //       { fillStyle: '#158e47', text: '35', value: 35 },
    //       { fillStyle: '#2ba089', size: 2, value: 90 },
    //       { fillStyle: '#40ecca', text: '90', value: 90 },
    //       { fillStyle: '#2d8788', size: 2, value: 45 },
    //       { fillStyle: '#43c7c9', text: '45', value: 45 },
    //       {
    //         fillStyle: '#adadad',
    //         size: 2,
    //         value: bonusPointsAwarded,
    //       },
    //       {
    //         fillStyle: '#ffffff',
    //         text: 'Logo',
    //         textOrientation: 'vertical',
    //         value: bonusPointsAwarded,
    //       },
    //     ]),

    //   animation: {
    //     type: 'spinToStop',
    //     duration: 8,
    //     spins: 3,
    //     callbackFinished: this.prizeCallback,
    //     callbackSound: this.playSound,
    //     soundTrigger: 'pin',
    //   },
    //   // Turn pins on.
    //   pins: {
    //     number: 24,
    //     outerRadius: 0,
    //   },
    // });
    const theWheel = new Winwheel({
      drawText: true,
      pointerAngle: 43,
      responsive: true,
      canvasId: 'wheelOfFortune',
      strokeStyle: 0,
      fillStyle: '#ffffff',
      lineWidth: 0,
      innerRadius: 50,
      textOrientation: 'curved',
      textAlignment: 'outer',
      textFontFamily: 'Poppins',
      textFontSize: '29',
      drawMode: 'segmentImage',
      numSegments: 24,
      segments: [
        { imgData: createImage(s1), text: '0', value: 0 },
        { imgData: createImage(s2), text: '95', value: 95 },
        { imgData: createImage(s3), text: '10', value: 10 },
        { imgData: createImage(s4), text: '60', value: 60 },
        { imgData: createImage(s5), text: '20', value: 20 },
      ]
        .concat(
          isPressYourLuckAllowed
            ? [{ imgData: createImage(press), value: 'pressYourLuck' }]
            : [{ imgData: createImage(s6), text: '0', value: 0 }]
        )
        .concat([
          { imgData: createImage(s7), text: '50', value: 50 },
          { imgData: createImage(s8), text: '75', value: 75 },
          { imgData: createImage(s9), text: '35', value: 35 },
          { imgData: createImage(s10), text: '90', value: 90 },
          { imgData: createImage(s11), text: '45', value: 45 },
          {
            imgData: createImage(logo),
            height: 300,
            width: 300,
            textOrientation: 'vertical',
            value: bonusPointsAwarded,
          },
          { imgData: createImage(s13), text: '0', value: 0 },
          { imgData: createImage(s14), text: '55', value: 55 },
          { imgData: createImage(s15), text: '15', value: 15 },
          { imgData: createImage(s16), text: '65', value: 65 },
          { imgData: createImage(s17), text: '70', value: 70 },
          { imgData: createImage(s18), text: '30', value: 30 },
          { imgData: createImage(s19), text: '80', value: 80 },
          { imgData: createImage(s20), text: '25', value: 25 },
          { imgData: createImage(s21), text: '85', value: 85 },
          { imgData: createImage(s22), text: '40', value: 40 },
          { imgData: createImage(s23), text: '5', value: 5 },
          { imgData: createImage(s24), text: '100', value: 100 },
        ]),

      animation: {
        type: 'spinToStop',
        duration: 8,
        spins: 3,
        callbackFinished: this.prizeCallback,
        callbackSound: this.playSound,
        soundTrigger: 'pin',
      },
      // Turn pins on.
      pins: {
        number: 24,
        outerRadius: 0,
      },
    });
    this.setState({ theWheel: theWheel });
    window.dispatchEvent(new Event('resize'));
  }

  playSound = () => {
    const { optionSound } = this.props;

    if (this.audio.currentTime > 0) {
      this.audio.pause();
    }
    if (optionSound) {
      this.audio.currentTime = 0;
      this.audio.play();
    }
  };

  startSpin = () => {
    const { theWheel } = this.state;
    const { startSpinCallback } = this.props;

    this.setState({ ready: false });

    if (typeof startSpinCallback === 'function') {
      startSpinCallback();
    }

    theWheel.stopAnimation(false);
    theWheel.rotationAngle = theWheel.rotationAngle % 360;
    theWheel.startAnimation();
  };

  prizeCallback = (data) => {
    const { prizeCallback } = this.props;

    this.setState({ ready: true });

    if (typeof prizeCallback === 'function') {
      prizeCallback(data);
    }
  };

  render() {
    const { disabled } = this.props;
    const { ready } = this.state;
    return (
      <div className='wheel-of-fortune'>
        <div className='outer'>
          <canvas
            id='wheelOfFortune'
            width='565px'
            height='565px'
            data-responsiveminwidth='300'
            data-responsivescaleheight='true'>
            Canvas not supported, use another browser.
          </canvas>
          <img className='arrow' src={arrow} alt='' />
          <button
            className={`spin-button ${ready ? 'ready' : ''}`}
            onClick={this.startSpin}
            disabled={disabled}>
            <svg
              width='100%'
              height='100%'
              viewBox='0 0 500 75'
              preserveAspectRatio='xMinYMid meet'>
              <text
                x='15'
                y='100'
                fontSize='180'
                fill={disabled ? '#888' : 'black'}>
                Spin
              </text>
            </svg>
          </button>
        </div>
        {window.dispatchEvent(new Event('resize'))}
      </div>
    );
  }
}

export default WheelOfFortune;
