import { FETCH_PRIZES, UPDATE_PRIZES } from './actions';
const initialState = {
  data: null,
  byCampaign: null,
};
export const prizes = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PRIZES: {
      return {
        ...state,
        byCampaign: payload,
      };
    }
    case UPDATE_PRIZES: {
      return {
        ...state,
        available: payload,
      };
    }
    default:
      return state;
  }
};
