import React from 'react';
import './CustomRadio.scss';

const CustomRadio = ({
  label = '',
  value = null,
  checked = false,
  onChange = () => {},
  img = null,
  disabled = false,
  name = '',
}) => {
  return (
    <div className='radio-btn-group'>
      <label className={checked ? 'checked' : 'unchecked'}>
        <input
          type='radio'
          value={value}
          checked={checked === value}
          onChange={() => onChange(value)}
          disabled={disabled}
        />
        <span className='text-label'>{label}</span>

        {img && <img src={img} alt='' />}
      </label>
    </div>
  );
};

export default CustomRadio;
