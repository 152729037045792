import React from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const Buttons = ({ onPrev = null, onSave = null, onNext = null, disabled }) => {
  return (
    <div className='submit-buttons'>
      {typeof onPrev === 'function' && (
        <button className='prev' type='button' onClick={onPrev}>
          <NavigateBeforeIcon />
          <span>Prev</span>
        </button>
      )}
      {typeof onSave === 'function' && (
        <button
          className='save'
          type='button'
          onClick={onSave}
          disabled={disabled}>
          Save
        </button>
      )}
      {typeof onNext === 'function' && (
        <button
          className='next'
          type='button'
          onClick={onNext}
          disabled={disabled}>
          <span>Next</span> <NavigateNextIcon />
        </button>
      )}
    </div>
  );
};

export default Buttons;
