import {
  REQUEST_START,
  REQUEST_STOP,
  REQUEST_CLEAN,
  FETCH_THEMES,
  SET_THEME,
} from './actions';

const initialState = {
  requestQueue: [],
  notifications: [],
  themes: null,
  activeTheme: localStorage.getItem('activeTheme'),
};

export const global = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST_START:
      return {
        ...state,
        requestQueue: [...state.requestQueue, payload],
      };

    case REQUEST_STOP:
      return {
        ...state,
        requestQueue: state.requestQueue.filter((e) => e !== payload),
      };

    case REQUEST_CLEAN:
      return {
        ...state,
        requestQueue: [],
      };
    case SET_THEME:
      localStorage.setItem('activeTheme', payload.toLowerCase());
      return {
        ...state,
        activeTheme: payload.toLowerCase(),
      };

    case FETCH_THEMES:
      return {
        ...state,
        themes: payload,
      };
    default:
      return state;
  }
};
