import { FETCH_BRANDIGN_INFO } from './actions';
const initialState = {
  client: null,
};
export const clients = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_BRANDIGN_INFO: {
      return {
        ...state,
        client: payload,
      };
    }

    default:
      return state;
  }
};
