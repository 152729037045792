import axios from 'axios';
import { api } from 'settings';
import querystring from 'querystring';
import { enqueueSnackbar } from 'store/notifications/actions';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const FETCH_USER = 'FETCH_USER';
export const TOGGLE_LOGOUT_DIALOG = 'TOGGLE_LOGOUT_DIALOG';
export const TOGGLE_RESET_PASS_DIALOG = 'TOGGLE_RESET_PASS_DIALOG';

export const login =
  ({ username, password, remember }) =>
  (dispatch) => {
    return axios
      .post(
        api.token,
        querystring.stringify({ grant_type: 'password', username, password }),
        {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        }
      )
      .then((response) => {
        const storage = remember ? localStorage : sessionStorage;
        storage.setItem('ACCESS_TOKEN', response.data.access_token);
        storage.setItem('TOKEN_TYPE', response.data.token_type);

        dispatch({
          type: LOGIN,
          payload: response.data.userId,
        });

        return response.data;
      });
  };

export const forgotPassword = (params) => (dispatch) => {
  return axios.post(api.account.forgotPassword, params).then(() =>
    dispatch(
      enqueueSnackbar({
        message: 'New password has been sent to your email',
        options: {
          variant: 'success',
        },
      })
    )
  );
};

export const frontEndLogout = () => (dispatch) => {
  localStorage.removeItem('ACCESS_TOKEN');
  localStorage.removeItem('TOKEN_TYPE');
  sessionStorage.removeItem('ACCESS_TOKEN');
  sessionStorage.removeItem('TOKEN_TYPE');
  dispatch({
    type: LOGOUT,
  });
};

export const logout = () => (dispatch) => {
  return axios.post(api.account.logout).then(() => {
    localStorage.removeItem('ACCESS_TOKEN');
    localStorage.removeItem('TOKEN_TYPE');

    sessionStorage.removeItem('ACCESS_TOKEN');
    sessionStorage.removeItem('TOKEN_TYPE');

    return dispatch({
      type: LOGOUT,
    });
  });
};

export const changePassword = (data) => (dispatch) => {
  return axios.post(`${api.account.changePassword}`, data).then(() =>
    dispatch(
      enqueueSnackbar({
        message: 'Your password has been changed',
        options: {
          variant: 'success',
        },
      })
    )
  );
};

export const fetchUser = (id) => (dispatch) => {
  return axios.get(`${api.account.user}/${id}`).then((response) => {
    dispatch({
      type: FETCH_USER,
      payload: response.data,
    });

    return response.data;
  });
};

export const toggleLogoutDialog = (payload) => ({
  type: TOGGLE_LOGOUT_DIALOG,
  payload,
});

export const toggleResetPassDialog = (payload) => ({
  type: TOGGLE_RESET_PASS_DIALOG,
  payload,
});
