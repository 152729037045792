import axios from 'axios';
import { api } from 'settings';

export const FETCH_CLIENT = 'FETCH_CLIENT';
export const FETCH_BRANDIGN_INFO = 'FETCH_BRANDIGN_INFO';

export const fetchClient = (clientId) => (dispatch) => {
  return axios.get(`${api.clients.get}/${clientId}`).then((response) => {
    dispatch({
      type: FETCH_CLIENT,
      payload: response.data,
    });
    return response.data;
  });
};

export const fetchBrandignInfo = (clientId) => (dispatch) => {
  return axios.get(`${api.clients.branding}/${clientId}`).then((response) => {
    dispatch({
      type: FETCH_BRANDIGN_INFO,
      payload: response.data,
    });
    return response.data;
  });
};

export const updateClient = (data) => (dispatch) => {
  return axios.post(`${api.clients.update}`, data).then((response) => {
    dispatch({
      type: FETCH_BRANDIGN_INFO,
      payload: response.data,
    });
    return response.data;
  });
};
