import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './Questions.scss';
import { Field, Form } from 'react-final-form';
import { RenderRadio, RenderInputText } from 'components/FormElements';
import arrayMutators from 'final-form-arrays';
import {
  fetchQuestions,
  downloadTemplate,
  uploadTemplate,
  deleteAllQuestions,
  updateQuestion,
  addQuestion,
  deleteQuestion,
  clearQuestions,
} from 'store/questions/actions';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import UploadTemplate from 'components/UploadTemplate';
import Buttons from '../Buttons';

const newQuestion = {
  questionType: 0,
  answers: [],
  isDeleted: false,
};

const trueFalse = [
  {
    answerText: 'True',
    id: '3a3de9af-9b97-4c80-b375-7f748549c2da',
    isDeleted: false,
  },
  {
    answerText: 'False',
    id: 'c8876639-4d0b-4b8f-ba7f-c133d357eadb',
    isDeleted: false,
  },
];

const required = (value) => (value ? undefined : 'This field is required');

const Questions = () => {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteAllDialog, setDeleteAllDialog] = useState(false);
  const [selectedQuestion, selectQuestion] = useState({ ...newQuestion });
  const [hasProgress, setProgress] = useState(true);
  const dispatch = useDispatch();
  const { userId, campaignId } = useParams();
  const history = useHistory();

  const { questions, campaigns } = useSelector((state) => ({
    questions: state.questions.data,
    campaigns: state.campaigns.available,
  }));

  useEffect(() => {
    if (campaignId && campaigns) {
      setProgress(
        campaigns.find(({ id }) => id === campaignId)?.hasCampaignUserProgress
      );
    }
  }, [campaignId, campaigns]);

  useEffect(() => {
    dispatch(fetchQuestions(campaignId));

    return () => {
      dispatch(clearQuestions());
    };
  }, [dispatch, campaignId]);

  const selectQuestionHandler = useCallback((question) => {
    const answers = question?.answers ? question.answers : trueFalse;
    const correctAnswerIndex = answers.findIndex(
      (answer) => answer.answerText === question?.correctAnswer?.answerText
    );
    selectQuestion({
      ...question,
      correctAnswerIndex: correctAnswerIndex + '',
    });
  }, []);

  const submitHandler = useCallback(
    (values, form) => {
      const { correctAnswerIndex, ...newValues } = values;

      const answers =
        values.questionType === 1 ? [...trueFalse] : [...values.answers];
      const handler = values.id ? updateQuestion : addQuestion;

      if (values.id) {
        newValues.correctAnswerId = answers[correctAnswerIndex].id;
      }
      newValues.answers = answers;
      newValues.correctAnswer = answers[correctAnswerIndex];

      return dispatch(handler(newValues, campaignId)).then(() => {
        selectQuestion({ ...newQuestion });
        form.restart({ ...newQuestion });
        return dispatch(fetchQuestions(campaignId));
      });
    },
    [campaignId, dispatch]
  );

  const deleteHandler = useCallback(
    (id) => {
      return dispatch(deleteQuestion(id)).then(() => {
        selectQuestion({ ...newQuestion });
        return dispatch(fetchQuestions(campaignId));
      });
    },
    [campaignId, dispatch]
  );
  return (
    <>
      <div className='steps-content question-content'>
        <h3>Questions</h3>
        <div className='question-section1'>
          <button
            className='gray-btn light delete-question'
            onClick={() => setDeleteAllDialog(true)}
            disabled={hasProgress}>
            Delete All Questions
          </button>
          <div className='block'>
            <span className='inner-title'>Bulk Operations</span>
            <div className='btn-block'>
              <button
                className='gray-btn light'
                onClick={() => dispatch(downloadTemplate(campaignId))}>
                Download Template
              </button>

              <UploadTemplate
                setFile={(file) => {
                  dispatch(uploadTemplate(file, campaignId));
                }}
                accept='.xlsx'>
                <button className='gray-btn light' disabled={hasProgress}>
                  Upload Template
                </button>
              </UploadTemplate>
            </div>
          </div>
        </div>
        <h3>Questions</h3>
        <div className='question-section'>
          <Form
            initialValues={selectedQuestion}
            onSubmit={submitHandler}
            validate={(values) => {
              const errors = {};
              if (!values.correctAnswerIndex) {
                errors.customError = 'Please select correct answer';
              }
              return errors;
            }}
            mutators={{
              ...arrayMutators,
            }}
            render={({
              pristine,
              submitting,
              handleSubmit,
              values,
              form,
              ...rest
            }) => (
              <form
                onSubmit={(event) => {
                  const promise = handleSubmit(event);
                  promise &&
                    promise.then(() => {
                      form.restart();
                    });
                  return promise;
                }}>
                <div className='button-row'>
                  <div className='switcher'>
                    <button
                      className={values?.questionType === 0 ? 'active' : ''}
                      type='button'
                      onClick={() => form.change('questionType', 0)}
                      disabled={selectedQuestion.id || submitting}>
                      Manual Input Multiple Choice
                    </button>
                    <button
                      className={values?.questionType === 1 ? 'active' : ''}
                      type='button'
                      onClick={() => form.change('questionType', 1)}
                      disabled={selectedQuestion.id || submitting}>
                      Manual Input True/False
                    </button>
                  </div>
                </div>
                {values?.questionType === 0 && (
                  <div className='multi-choice'>
                    <div className='row-block question flex justify-between mb-10'>
                      <Field
                        name={`questionText`}
                        label={`Question`}
                        component={RenderInputText}
                        disabled={submitting || hasProgress}
                        validate={required}
                      />
                    </div>
                    <div className='row-block question-answer flex justify-between mb-10'>
                      <Field
                        name={`answers[0].answerText`}
                        label={`Answer 1`}
                        component={RenderInputText}
                        disabled={submitting || hasProgress}
                        validate={required}
                      />
                      <Field
                        name='correctAnswerIndex'
                        type='radio'
                        value={'0'}
                        component={RenderRadio}
                        disabled={submitting || hasProgress}
                        validate={required}
                      />
                    </div>
                    <div className='row-block question-answer flex justify-between mb-10'>
                      <Field
                        name={`answers[1].answerText`}
                        label={`Answer 2`}
                        component={RenderInputText}
                        disabled={submitting || hasProgress}
                        validate={required}
                      />
                      <Field
                        name='correctAnswerIndex'
                        type='radio'
                        value={'1'}
                        component={RenderRadio}
                        disabled={submitting || hasProgress}
                      />
                    </div>
                    <div className='row-block question-answer flex justify-between mb-10'>
                      <Field
                        name={`answers[2].answerText`}
                        label={`Answer 3`}
                        component={RenderInputText}
                        disabled={submitting || hasProgress}
                        validate={required}
                      />
                      <Field
                        name='correctAnswerIndex'
                        type='radio'
                        value={'2'}
                        component={RenderRadio}
                        disabled={submitting || hasProgress}
                      />
                    </div>
                    <div className='row-block question-answer flex justify-between mb-10'>
                      <Field
                        name={`answers[3].answerText`}
                        label={`Answer 4`}
                        component={RenderInputText}
                        disabled={submitting || hasProgress}
                        validate={required}
                      />
                      <Field
                        name='correctAnswerIndex'
                        type='radio'
                        value={'3'}
                        component={RenderRadio}
                        disabled={submitting || hasProgress}
                      />
                    </div>
                  </div>
                )}

                <Field
                  name={`customError`}
                  component={({ meta }) => (
                    <div className='field-component'>
                      {meta.error && meta.touched && (
                        <div className='error-helper-text'>{meta.error}</div>
                      )}
                    </div>
                  )}
                />

                {values?.questionType === 1 && (
                  <div className='true-false-choice'>
                    <div className='row-block question flex justify-between mb-10'>
                      <Field
                        name={`questionText`}
                        label={`Question`}
                        component={RenderInputText}
                        disabled={submitting || hasProgress}
                      />
                    </div>
                    <div className='row-block question-answer flex mb-10'>
                      <div className='form-group'>
                        <label>TRUE</label>
                      </div>
                      <Field
                        name='correctAnswerIndex'
                        type='radio'
                        value={'0'}
                        component={RenderRadio}
                        disabled={submitting || hasProgress}
                      />
                    </div>
                    <div className='row-block question-answer flex mb-10'>
                      <div className='form-group'>
                        <label>FALSE</label>
                      </div>
                      <Field
                        name='correctAnswerIndex'
                        type='radio'
                        value={'1'}
                        component={RenderRadio}
                        disabled={submitting || hasProgress}
                      />
                    </div>
                  </div>
                )}

                <div className='btn-block'>
                  {values?.id ? (
                    <>
                      <button
                        type='submit'
                        disabled={submitting || hasProgress}
                        className='update green-btn'>
                        Update Question
                      </button>
                      <button
                        className='delete red-btn'
                        type='button'
                        disabled={submitting || hasProgress}
                        onClick={() => setDeleteDialog(true)}>
                        Delete Question
                      </button>
                      <button
                        className='update gray-btn2'
                        type='button'
                        onClick={() => {
                          selectQuestion({ ...newQuestion });
                          form.restart();
                        }}>
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type='submit'
                        className='update green-btn'
                        disabled={pristine || submitting || hasProgress}>
                        Add Question
                      </button>
                      <button
                        className='update gray-btn2'
                        type='button'
                        onClick={() => {
                          selectQuestion({ ...newQuestion });
                          form.restart();
                        }}>
                        Clear
                      </button>
                    </>
                  )}
                </div>

                {/* <div>
                  <pre>{JSON.stringify(values, undefined, 2)}</pre>
                </div> */}
              </form>
            )}
          />
        </div>

        {questions && questions.length > 0 && (
          <div className='question-list'>
            <table>
              <thead>
                <tr>
                  <th>
                    <span>Question</span>
                  </th>
                  <th>
                    <span>Answer 1</span>
                  </th>
                  <th>
                    <span>Answer 2</span>
                  </th>
                  <th>
                    <span>Answer 3</span>
                  </th>
                  <th>
                    <span>Answer 4</span>
                  </th>
                  <th>
                    <span>Correct</span>
                  </th>
                  <th>
                    <span>Select</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {questions.map((question) => (
                  <tr key={question.id}>
                    <td>
                      <span>{question.questionText}</span>
                    </td>
                    {question.questionType === 0 ? (
                      question.answers.map((answer) => (
                        <td key={answer.id}>
                          <span>{answer.answerText}</span>
                        </td>
                      ))
                    ) : (
                      <td colSpan={4} />
                    )}
                    <td>
                      <span>
                        <b>{question?.correctAnswer?.answerText}</b>
                      </span>
                    </td>
                    <td className='select-radio'>
                      <input
                        type='radio'
                        checked={selectedQuestion?.id === question.id}
                        onChange={() => selectQuestionHandler(question)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <Buttons
          onPrev={() =>
            history.push(`/user/${userId}/game-setup/${campaignId}`)
          }
          onSave={() => {}}
          onNext={() => history.push(`/user/${userId}/users/${campaignId}`)}
          disabled={questions && questions.length === 0}
        />
        <Dialog open={deleteDialog}>
          <div className='quit-dialog'>
            <h3 className='quit-dialog-title2'>
              Are you sure you want to delete this question?
            </h3>

            <div className='quit-dialog-buttons'>
              <button
                onClick={() =>
                  deleteHandler(selectedQuestion.id).then(() =>
                    setDeleteDialog(false)
                  )
                }>
                Yes
              </button>
              <button onClick={() => setDeleteDialog(false)}>No</button>
            </div>
          </div>
        </Dialog>

        <Dialog open={deleteAllDialog}>
          <div className='quit-dialog'>
            <h3 className='quit-dialog-title2'>
              Are you sure you want to delete all questions?
            </h3>

            <div className='quit-dialog-buttons'>
              <button
                onClick={() =>
                  dispatch(deleteAllQuestions(campaignId))
                    .then(() => dispatch(fetchQuestions(campaignId)))
                    .then(() => setDeleteAllDialog(false))
                }>
                Yes
              </button>
              <button onClick={() => setDeleteAllDialog(false)}>No</button>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default Questions;
