import axios from 'axios';
import { api } from 'settings';

export const FETCH_PRIZES = 'FETCH_PRIZES';
export const UPDATE_PRIZES = 'UPDATE_PRIZES';

export const fetchPrizes = (campaignId) => (dispatch) => {
  return axios.get(`${api.prizes.get}/${campaignId}`).then((response) => {
    dispatch({
      type: FETCH_PRIZES,
      payload: response.data,
    });
    return response.data;
  });
};

export const updatePrizes = (id, data) => (dispatch) => {
  return axios.post(`${api.prizes.bulk}/${id}`, data).then((response) => {
    dispatch({
      type: UPDATE_PRIZES,
      payload: response.data,
    });
    return response.data;
  });
};

export const addAwardedPrizeForCampaign = (data) => (dispatch) => {
  return axios.post(`${api.prizes.awarded}`, data).then((response) => {
    return response.data;
  });
};
