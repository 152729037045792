import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({
  isAuthenticated,
  isPermitted = false,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          isPermitted ? (
            <Component {...props} {...rest} />
          ) : (
            <p style={{ color: 'red' }}>
              Requested information is not permitted
            </p>
          )
        ) : (
          <Redirect to='/login' />
        )
      }
    />
  );
};

export default PrivateRoute;
