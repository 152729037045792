import {
  FETCH_AVAILABLE_CAMPAIGNS,
  FETCH_AVAILABLE_CAMPAIGNS_FOR_CLIENT,
  FETCH_CAMPAIGN_TYPES,
  CREATE_CAMPAIGN,
  FETCH_CAMPAIGNS_LIGHT,
  // UPDATE_CAMPAIGN,
} from './actions';
const initialState = {
  available: null,
  types: null,
  light: null,
  availableForClient: null,
};
export const campaigns = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_AVAILABLE_CAMPAIGNS: {
      return {
        ...state,
        available1: payload,
      };
    }
    case FETCH_AVAILABLE_CAMPAIGNS_FOR_CLIENT: {
      return {
        ...state,
        available: payload,
      };
    }
    case FETCH_CAMPAIGNS_LIGHT: {
      return {
        ...state,
        light: payload[0].clientCampaigns,
      };
    }
    case FETCH_CAMPAIGN_TYPES: {
      return {
        ...state,
        types: payload,
      };
    }

    case CREATE_CAMPAIGN: {
      return {
        ...state,
        available: state.available ? [...state.available, payload] : [payload],
      };
    }

    // case UPDATE_CAMPAIGN: {
    //   return {
    //     ...state,
    //     available: state.available ? [...state.available, payload] : [payload],
    //   };
    // }

    default:
      return state;
  }
};
