import React, { useRef } from 'react';
import './FormElements.scss';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const RenderDatePicker = ({
  input: { onChange, onBlur, value },
  className,
  meta,
  ...rest
}) => {
  return (
    <div className='field-component'>
      <DatePicker
        {...rest}
        className={
          className ||
          `custom-date-picker ${meta.error && meta.touched && 'error-field'}`
        }
        selected={value ? new Date(value) : null}
        onChange={(date) => onChange(moment(date).format())}
        onChangeRaw={(e) => e.preventDefault()}
        onBlur={(event) => onBlur(event)}
      />
      {meta.error && meta.touched && (
        <div className='error-helper-text'>{meta.error}</div>
      )}
    </div>
  );
};
export const RenderRadio = ({ input, label, changeCallback, disabled }) => (
  <div className='radio-btn-group'>
    <label>
      <input
        type='radio'
        {...input}
        onChange={(event) => {
          input.onChange(event.target.value);
          if (changeCallback) {
            changeCallback(event.target.value);
          }
        }}
        disabled={disabled}
      />
      <span className='text-label'>{label}</span>
    </label>
  </div>
);

export const RenderInputTextGray = ({ input, meta, disabled }) => {
  return (
    <div className='field-component'>
      <input
        {...input}
        className={`campaign-name ${
          meta.error && meta.touched && 'error-field'
        }`}
        disabled={disabled}
      />
      {meta.error && meta.touched && (
        <div className='error-helper-text'>{meta.error}</div>
      )}
    </div>
  );
};
export const RenderInputText = ({ input, meta, label, disabled }) => {
  return (
    <div className='field-component'>
      <div className='form-group'>
        <label>{label}</label>
        <div className='input'>
          <input
            {...input}
            className={`fonm-input ${
              meta.error && meta.touched && 'error-field'
            }`}
            disabled={disabled}
          />
        </div>
      </div>
      {meta.error && meta.touched && (
        <div className='error-helper-text'>{meta.error}</div>
      )}
    </div>
  );
};

export const RenderTextArea = ({ input, meta, label, disabled }) => (
  <div className='field-component'>
    <div className='form-group'>
      <label>{label}</label>
      <div className='textarea'>
        <textarea
          {...input}
          className={`form-textarea ${
            meta.error && meta.touched && 'error-field'
          }`}
          disabled={disabled}></textarea>
      </div>
    </div>
    {meta.error && meta.touched && (
      <div className='error-helper-text'>{meta.error}</div>
    )}
  </div>
);

export const RenderFileField = ({ input, meta, disabled }) => {
  const ref = useRef();

  const handleChange = (event) => {
    let newFile = event.target.files[0];

    if (newFile) {
      const reader = new FileReader();
      reader.readAsDataURL(newFile);
      reader.onload = () => {
        const fileInfo = {
          id: input.value.id,
          fileName: newFile.name,
          mimeType: newFile.type,
          base64String: reader.result.split(',')[1],
          isDeleted: false,
        };
        input.onChange(fileInfo);
      };
    }
  };

  return (
    <div className='field-component'>
      <div className='upload-file'>
        <label
          onClick={() => {
            if (ref) {
              ref.current.value = null;
              ref.current.click();
            }
          }}>
          Browse
        </label>
        <input
          type='file'
          style={{ display: 'none' }}
          onChange={handleChange}
          ref={ref}
          disabled={disabled}
          accept='.png, .jpg, .jpeg'
        />
        <div
          className={`upload-img-block ${
            meta.error && meta.touched && 'error-field'
          }`}>
          {input.value && (
            <img
              src={
                input.value?.base64String
                  ? `data:${input.value.mimeType};base64,${input.value.base64String}`
                  : input.value.url
              }
              alt=''
            />
          )}
        </div>
      </div>
      {meta.error && meta.touched && (
        <div className='error-helper-text'>{meta.error}</div>
      )}
    </div>
  );
};

export const RenderAvatarField = ({
  input,
  meta,
  label,
  disabled,
  description,
}) => {
  const ref = useRef();

  const handleChange = (event) => {
    let newFile = event.target.files[0];
    if (newFile) {
      const reader = new FileReader();
      reader.readAsDataURL(newFile);
      reader.onload = () => {
        const fileInfo = {
          id: input.value.id,
          fileName: newFile.name,
          description,
          mimeType: newFile.type,
          base64String: reader.result.split(',')[1],
          isDeleted: false,
        };
        input.onChange(fileInfo);
      };
    }
  };

  return (
    <div className='add-avatar'>
      <div className='form-checkbox'>
        <label>{label}</label>
      </div>
      <div className='avatar'>
        <div className='upload-img-block'>
          <div className='border-for-img'>
            {input.value && (
              <img
                src={
                  input.value?.base64String
                    ? `data:${input.value.mimeType};base64,${input.value.base64String}`
                    : input.value.url
                }
                alt=''
              />
            )}
          </div>
        </div>

        <input
          type='file'
          style={{ display: 'none' }}
          onChange={handleChange}
          ref={ref}
          disabled={disabled}
          accept='.png, .jpg, .jpeg'
        />
        <button
          type='button'
          className='gray-btn light'
          onClick={() => {
            if (ref) {
              ref.current.value = null;
              ref.current.click();
            }
          }}>
          Browse
        </button>
      </div>
    </div>
  );

  // return (
  //   <div className='field-component'>
  //     <div className='upload-file'>
  //       <label onClick={() => ref && ref.current.click()}>Browse</label>
  //       <input
  //         type='file'
  //         style={{ display: 'none' }}
  //         onChange={handleChange}
  //         ref={ref}
  //         disabled={disabled}
  //       />
  //       <div
  //         className={`upload-img-block ${
  //           meta.error && meta.touched && 'error-field'
  //         }`}>
  //         {input.value && (
  //           <img
  //             src={
  //               input.value?.base64String
  //                 ? `data:${input.value.mimeType};base64,${input.value.base64String}`
  //                 : input.value.url
  //             }
  //             alt=''
  //           />
  //         )}
  //       </div>
  //     </div>
  //     {meta.error && meta.touched && (
  //       <div className='error-helper-text'>{meta.error}</div>
  //     )}
  //   </div>
  // );
};
