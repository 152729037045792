import React, { useEffect } from 'react';
import { Switch, Redirect, useLocation, useParams } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';
import MainLogo from 'components/MainLogo/MainLogo';
import Header from './Header';
import Steps from './Steps';
import Branding from './Branding';
import GameSetup from './GameSetup';
import Questions from './Questions';
import Users from './Users';
import Report from './Report';
import './Admin.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  toggleLogoutDialog,
  toggleResetPassDialog,
} from 'store/authentication/actions';
import {
  fetchAvailableCampaignsForClient,
  fetchCampaignTypes,
} from 'store/campaigns/actions';
import { fetchThemes, setTheme } from 'store/global/actions';

import { fetchBrandignInfo } from 'store/clients/actions';

let Admin = ({ isAuthenticated, user }) => {
  const { step } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const { clientId } = useSelector((state) => ({
    clientId: state.authentication.user.clientId,
  }));

  useEffect(() => {
    if (clientId) {
      //dispatch(fetchAvailableCampaigns(clientId));
      dispatch(fetchAvailableCampaignsForClient(clientId));
      dispatch(fetchThemes());
      dispatch(fetchCampaignTypes());
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    dispatch(fetchBrandignInfo(clientId)).then(({ theme }) => {
      dispatch(setTheme(theme?.description || 'full'));
    });
    // dispatch(fetchClient(clientId)).then(({ theme }) => {
    //   dispatch(setTheme(theme?.description || 'full'));
    // });
  }, [dispatch, clientId]);

  return (
    <div className='containers'>
      <div className='admin-panel'>
        <MainLogo />
        <div className='admin-panel-container'>
          <Header
            user={user}
            toggleResetPassDialog={(data) =>
              dispatch(toggleResetPassDialog(data))
            }
            toggleLogoutDialog={(data) => dispatch(toggleLogoutDialog(data))}
          />
          <Steps activeStep={step} />

          <Switch>
            <PrivateRoute
              exact
              path={'/user/:userId/branding'}
              isAuthenticated={isAuthenticated}
              isPermitted={user.roles.includes('Admin')}
              component={Branding}
            />
            <PrivateRoute
              exact
              path={'/user/:userId/game-setup/:campaignId?'}
              isAuthenticated={isAuthenticated}
              isPermitted={user.roles.includes('Admin')}
              component={GameSetup}
            />
            <PrivateRoute
              exact
              path={'/user/:userId/questions/:campaignId'}
              isAuthenticated={isAuthenticated}
              isPermitted={user.roles.includes('Admin')}
              component={Questions}
            />
            <PrivateRoute
              exact
              path={'/user/:userId/users/:campaignId'}
              isAuthenticated={isAuthenticated}
              isPermitted={user.roles.includes('Admin')}
              component={Users}
            />
            <PrivateRoute
              exact
              path={'/user/:userId/report/:campaignId?'}
              isAuthenticated={isAuthenticated}
              isPermitted={user.roles.includes('Admin')}
              component={Report}
            />

            <Redirect to={`${location.pathname}/branding`} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Admin;
