import React from 'react';
import './style.scss';

const Header = ({ user, toggleResetPassDialog, toggleLogoutDialog }) => {
  return (
    <div className='welcome-header'>
      <div className='name'>{`${user.fullName}`}</div>

      <div className='links'>
        <button
          className='change-pass'
          onClick={() => toggleResetPassDialog(true)}>
          Change Password
        </button>
        <button className='log-off' onClick={() => toggleLogoutDialog(true)}>
          Log Off
        </button>
      </div>
    </div>
  );
};

export default Header;
