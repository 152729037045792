import axios from 'axios';
import { api } from 'settings';

export const FETCH_USER_ANSWERS = 'FETCH_USER_ANSWERS';

export const fetchUserAnswers = (userId) => (dispatch) => {
  return axios.get(`${api.userAnswers.get}`).then((response) => {
    dispatch({
      type: FETCH_USER_ANSWERS,
      payload: response.data,
    });
    return response.data;
  });
};

export const postUserAnswer = (data) => (dispatch) => {
  return axios.post(`${api.userAnswers.post}`, data);
};
