import {
  FETCH_GAME_DETAILS,
  FETCH_GAME_PROGRESS,
  FETCH_GAME_STATUS,
  FETCH_AVAILABLE_PRIZES,
  SELECT_CAMPAIGN,
  CLEAR_GAME_DETAILS,
} from './actions';
const initialState = {
  details: null,
  progress: null,
  status: null,
  availablePrizes: null,
  selectedCampaign: null,
};
export const games = (state = initialState, { type, payload }) => {
  switch (type) {
    case CLEAR_GAME_DETAILS: {
      return {
        ...state,
        details: null,
      };
    }
    case FETCH_GAME_DETAILS: {
      return {
        ...state,
        details: payload,
      };
    }
    case FETCH_GAME_PROGRESS: {
      return {
        ...state,
        progress: payload ? payload : [],
      };
    }
    case FETCH_GAME_STATUS: {
      return {
        ...state,
        status: payload,
      };
    }
    case FETCH_AVAILABLE_PRIZES: {
      return {
        ...state,
        availablePrizes: payload,
      };
    }
    case SELECT_CAMPAIGN: {
      return {
        ...state,
        selectedCampaign: payload,
      };
    }

    default:
      return state;
  }
};
