import React from 'react';
import Dialog from '@material-ui/core/Dialog';

import './QuizEndDialog.scss';

let QuizEndDialog = ({
  isOpened,
  isContinue,
  points,
  onSmartSpin,
  onLogout,
}) => {
  // if(isContinue && points === 0){
  //   alert("true")
  //   alert(points)
  //   onLogout();
  // }else{
  //   alert("false")
  // }
  return (
    <Dialog open={isOpened}>
      <div className='quiz-end-dialog'>
        <div className='points'>{points}</div>
        <div className='description'>
          {isContinue ? 'Spins' : 'Points'} Earned
        </div>

        <div className='quiz-end-dialog-buttons'>
          {isContinue && points > 0 ? (
            <button onClick={onSmartSpin}>
              <span style={{ position: 'relative', top: '-3px' }}>
                Start SmartSpin
                <sup style={{ fontSize: '50%' }}>SM</sup>
              </span>
            </button>
          ) : (
            <button onClick={onLogout}>
              {isContinue ? 'Log Out' : 'Quit Game'}
            </button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default QuizEndDialog;
