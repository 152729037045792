import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Switch, Redirect, useLocation, useParams } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';
import Welcome from 'containers/Game/Welcome';
import Quiz from 'containers/Game/Quiz';
import Spin from 'containers/Game/Spin';
import {
  fetchGameDetails,
  fetchGameProgress,
  fetchGameStatus,
} from 'store/games/actions';

import { getActiveCanpaign } from 'store/games/selectors';
import MainLogo from 'components/MainLogo/MainLogo';
import { selectCampaign } from 'store/games/actions';
import { setTheme } from 'store/global/actions';

let Game = ({ user, isAuthenticated }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [updater, setUpdater] = useState(new Date());
  const { campaignId } = useParams();

  const { gameDetails, activeCanpaign } = useSelector(
    (state) => ({
      gameDetails: state.games.details,
      activeCanpaign: getActiveCanpaign(state.games),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (gameDetails) {
      const activeCanpaigns = gameDetails[0].campaigns;
      const selectedCampagn = activeCanpaigns.find((e) => e.id === campaignId);

      if (activeCanpaign?.id !== selectedCampagn?.id) {
        dispatch(selectCampaign(selectedCampagn));
      }
    }
  }, [activeCanpaign, campaignId, dispatch, gameDetails]);

  useEffect(() => {
    dispatch(fetchGameDetails(user.id)).then((response) => {
      dispatch(setTheme(response ? response[0].theme.description : 'full'));
    });
  }, [dispatch, user.id, updater]);

  useEffect(() => {
    if (activeCanpaign) {
      dispatch(
        fetchGameStatus({
          campaignId: activeCanpaign.id,
          userId: user.id,
        })
      );
      dispatch(
        fetchGameProgress({
          campaignId: activeCanpaign.id,
          userId: user.id,
        })
      );
    }
  }, [activeCanpaign, dispatch, user.id]);

  return (
    <div className='containers'>
      <MainLogo />

      <Switch>
        <PrivateRoute
          exact
          path={'/user/:userId/welcome/:campaignId?'}
          isAuthenticated={isAuthenticated}
          isPermitted={!user.roles.includes('Admin')}
          component={Welcome}
        />

        <PrivateRoute
          exact
          path={'/user/:userId/quiz/:campaignId'}
          isAuthenticated={isAuthenticated}
          isPermitted={!user.roles.includes('Admin')}
          component={Quiz}
          setUpdater={setUpdater}
        />

        <PrivateRoute
          exact
          path={'/user/:userId/spin/:campaignId'}
          isAuthenticated={isAuthenticated}
          isPermitted={!user.roles.includes('Admin')}
          component={Spin}
        />

        <Redirect to={`${location.pathname}/welcome`} />
      </Switch>
    </div>
  );
};

export default Game;
