import {
  FETCH_AGENT_PARTICIPATION,
  FETCH_AVERAGE_QUIZ_SCORE,
  FETCH_QUIZ_QUESTION_OVERVIEW_DETAILS,
  FETCH_QUIZ_USER_OVERVIEW,
  FETCH_USER_POINTS_PRIZES_OVERVIEW,
  CLEAR_REPORTS,
} from './actions';
const initialState = {
  agentParticipation: null,
  averageQuizScore: null,
  quizQuestionOverviewDetails: [],
  quizUserOverview: [],
  userPointsPrizesOverview: [],
};
export const reports = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_AGENT_PARTICIPATION: {
      return {
        ...state,
        agentParticipation: payload,
      };
    }

    case FETCH_AVERAGE_QUIZ_SCORE: {
      return {
        ...state,
        averageQuizScore: payload,
      };
    }

    case FETCH_QUIZ_QUESTION_OVERVIEW_DETAILS: {
      return {
        ...state,
        quizQuestionOverviewDetails: payload,
      };
    }

    case FETCH_QUIZ_USER_OVERVIEW: {
      return {
        ...state,
        quizUserOverview: payload,
      };
    }

    case FETCH_USER_POINTS_PRIZES_OVERVIEW: {
      return {
        ...state,
        userPointsPrizesOverview: payload,
      };
    }
    case CLEAR_REPORTS: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};
