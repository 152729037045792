const selectStyles = {
  control: (provided) => ({
    ...provided,
    borderWidth: 0,
    borderRadius: 0,
    background: '#A1A1A1',
    boxShadow: 'none',
    fontSize: '11px',
    lineHeight: '18px',
    fontFamily: 'Poppins',
    cursor: 'pointer',
    '&:hover': { borderColor: 'transparent' },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#373737',
    '&:hover': {
      color: '#373737',
    },
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    maxHeight: 200,
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '11px',
    lineHeight: '18px',
    fontFamily: 'Poppins',
    margin: 0,
    background: '#fff',
    color: '#373737',
    cursor: 'pointer',
    '&:active': {
      background: '#56bdea',
    },
    '&:hover': {
      background: '#56bdea',
    },
  }),
};

const selectStylesLight = {
  control: (provided) => ({
    ...provided,
    borderWidth: 1,
    borderRadius: 0,
    background: '#f1f1f1',
    boxShadow: 'none',
    fontSize: '11px',
    lineHeight: '18px',
    fontFamily: 'Poppins',
    cursor: 'pointer',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#373737',
    '&:hover': {
      color: '#373737',
    },
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '11px',
    lineHeight: '18px',
    fontFamily: 'Poppins',
    margin: 0,
    background: '#fff',
    color: '#373737',
    cursor: 'pointer',
    '&:active': {
      background: '#56bdea',
    },
    '&:hover': {
      background: '#56bdea',
    },
  }),
};

export { selectStyles, selectStylesLight };
