import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { login, fetchUser, forgotPassword } from 'store/authentication/actions';
import MainLogo from '../../components/MainLogo/MainLogo';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';

import Input from './Input/Input';

import './Login.scss';

class Login extends PureComponent {
  state = {
    username: '',
    password: '',
    remember: false,
    isLogin: true,
    pending: false,
    error: '',
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { login, forgotPassword } = this.props;
    const { username, password, remember, isLogin } = this.state;
    this.setState({ error: '' });

    if (!username.trim()) {
      this.setState({ error: 'Username is required' });
      return;
    }

    this.setState({ pending: true });

    if (isLogin) {
      login({ username, password, remember }).catch(() =>
        this.setState({ pending: false })
      );
    } else {
      forgotPassword({ Email: username })
        .then(() => {
          this.setState({ pending: false, isLogin: true });
        })
        .catch(() => this.setState({ pending: false }));
    }
  };

  handleCheckboxChange = (event) => {
    this.setState({ remember: !this.state.remember });
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  isForgotPass = (e) => {
    e.preventDefault();
    this.setState({ isLogin: !this.state.isLogin, error: '' });
  };

  render() {
    const { pending, isLogin, error } = this.state;
    const { history } = this.props;

    const thankyou = new URLSearchParams(this.props.location.search).get(
      'thankyou'
    );

    return (
      <>
        <div className='login flex justify-center align-center'>
          <div className='inner-content'>
            <MainLogo />
            <h2> {isLogin ? 'Login' : 'Forgot Password?'} </h2>
            <form onSubmit={this.onSubmit}>
              <Input
                name={'username'}
                type={'text'}
                value={this.state.username}
                placeholder={'Type your username'}
                handleChange={this.handleChange}
                dataLabel={'Username'}
                icon={<PersonIcon style={{ fontSize: 17 }} />}
                disabled={pending}
              />
              {isLogin && (
                <>
                  <Input
                    name={'password'}
                    type={'password'}
                    value={this.state.password}
                    placeholder={'Type your password'}
                    handleChange={this.handleChange}
                    dataLabel={'Password'}
                    icon={<LockIcon style={{ fontSize: 16 }} />}
                    disabled={pending}
                  />
                  <div className='form-checkbox'>
                    <label>
                      <input
                        type='checkbox'
                        checked={this.state.remember}
                        onChange={this.handleCheckboxChange}
                        disabled={pending}
                      />
                      Remember me
                    </label>
                  </div>
                </>
              )}

              {error && <div className='error'>{error}</div>}

              <button type='submit' className='login-btn' disabled={pending}>
                {isLogin ? 'Login' : 'Reset Password'}
              </button>

              <button
                className='forgot-pass'
                onClick={this.isForgotPass}
                disabled={pending}>
                {isLogin ? 'Forgot password?' : 'Back to login'}
              </button>
            </form>
          </div>
        </div>
        <Dialog open={!!thankyou}>
          <div className='thankyou-dialog'>
            <IconButton
              size='small'
              className='thankyou-button'
              onClick={() => history.push('/login')}>
              <CloseIcon fontSize='inherit' />
            </IconButton>
            <h3 className='thankyou-title'>Thank you for your participation</h3>
          </div>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  login,
  fetchUser,
  forgotPassword,
};

Login = connect(mapStateToProps, mapDispatchToProps)(Login);
Login = withRouter(Login);

export default Login;
