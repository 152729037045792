import axios from 'axios';
import { api } from 'settings';

export const FETCH_AGENT_PARTICIPATION = 'FETCH_AGENT_PARTICIPATION';
export const FETCH_AVERAGE_QUIZ_SCORE = 'FETCH_AVERAGE_QUIZ_SCORE';
export const FETCH_QUIZ_QUESTION_OVERVIEW_DETAILS =
  'FETCH_QUIZ_QUESTION_OVERVIEW_DETAILS';
export const FETCH_QUIZ_USER_OVERVIEW = 'FETCH_QUIZ_USER_OVERVIEW';
export const FETCH_USER_POINTS_PRIZES_OVERVIEW =
  'FETCH_USER_POINTS_PRIZES_OVERVIEW';
export const CLEAR_REPORTS = 'CLEAR_REPORTS';

export const fetchAgentParticipation = (campaignId) => (dispatch) => {
  return axios
    .get(`${api.reports.getAgentParticipation}/${campaignId}`)
    .then((response) => {
      dispatch({
        type: FETCH_AGENT_PARTICIPATION,
        payload: response.data,
      });
      return response.data;
    });
};

export const fetchAverageQuizScore = (campaignId) => (dispatch) => {
  return axios
    .get(`${api.reports.getAverageQuizScore}/${campaignId}`)
    .then((response) => {
      dispatch({
        type: FETCH_AVERAGE_QUIZ_SCORE,
        payload: response.data,
      });
      return response.data;
    });
};

export const fetchQuizQuestionOverviewDetails = (campaignId) => (dispatch) => {
  return axios
    .get(`${api.reports.getQuizQuestionOverviewDetails}/${campaignId}`)
    .then((response) => {
      dispatch({
        type: FETCH_QUIZ_QUESTION_OVERVIEW_DETAILS,
        payload: response.data,
      });
      return response.data;
    });
};

export const fetchQuizUserOverview = (campaignId) => (dispatch) => {
  return axios
    .get(`${api.reports.getQuizUserOverview}/${campaignId}`)
    .then((response) => {
      dispatch({
        type: FETCH_QUIZ_USER_OVERVIEW,
        payload: response.data,
      });
      return response.data;
    });
};

export const fetchUserPointsPrizesOverview = (campaignId) => (dispatch) => {
  return axios
    .get(`${api.reports.getUserPointsPrizesOverview}/${campaignId}`)
    .then((response) => {
      dispatch({
        type: FETCH_USER_POINTS_PRIZES_OVERVIEW,
        payload: response.data,
      });
      return response.data;
    });
};

export const clearReports = (campaignId) => (dispatch) =>
  dispatch({
    type: CLEAR_REPORTS,
  });

export const downloadReport = (campaignId) => (dispatch) => {
  return axios
    .post(`${api.reports.getMainReportByCampaign}/${campaignId}`, null, {
      headers: {
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    })
    .then((response) => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const file = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const fileURL = window.URL.createObjectURL(file);
      a.href = fileURL;
      a.download = response.headers['content-disposition'].split('=')[1];
      a.click();
      return response.data;
    });
};

export const downloadQuestionReport =
  (campaignId, questionId) => (dispatch) => {
    return axios
      .post(
        `${api.reports.getReportQuestionUserDetailReport}/${campaignId}/${questionId}`,
        null,
        {
          headers: {
            'Content-Type':
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'arraybuffer',
        }
      )
      .then((response) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        const file = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        const fileURL = window.URL.createObjectURL(file);
        a.href = fileURL;
        a.download = response.headers['content-disposition'].split('=')[1];
        a.click();
        return response.data;
      });
  };

export const downloadUserReport = (campaignId, userId) => (dispatch) => {
  return axios
    .post(
      `${api.reports.getReportUserQuestionAnswerDetailReport}/${campaignId}/${userId}`,
      null,
      {
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'arraybuffer',
      }
    )
    .then((response) => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const file = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const fileURL = window.URL.createObjectURL(file);
      a.href = fileURL;
      a.download = response.headers['content-disposition'].split('=')[1];
      a.click();
      return response.data;
    });
};
