import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getLogo } from 'store/games/selectors';
import { getClientLogo } from 'store/clients/selectors';
import './BrandLogo.scss';

const BrandLogo = () => {
  const { logo } = useSelector(
    (state) => ({
      logo: getLogo(state.games) ||
        getClientLogo(state.clients) || {
          base64String:
            'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/5+hHgAHggJ/PchI7wAAAABJRU5ErkJggg==',
        },
    }),
    shallowEqual
  );

  return (
    <div className='brand-logo'>
      <img
        src={
          logo?.base64String
            ? `data:${logo.mimeType};base64,${logo.base64String}`
            : logo?.url
        }
        alt=''
      />
    </div>
  );
};
export default BrandLogo;
