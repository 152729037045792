import React, { PureComponent } from 'react';
import './style.scss';
import whammy from './assets/whammy.png';
import giftWhite from './assets/gift-white.png';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addAwardedPrizeForCampaign } from 'store/prizes/actions';
import { updateGameStatus } from 'store/games/actions';
import { getActiveCanpaign } from 'store/games/selectors';

import blinking from './assets/blinking.mp3';
import winning from './assets/winning.mp3';
import loseing from './assets/loseing.mp3';

class PressYourLuck extends PureComponent {
  state = {
    availableCells: [
      {
        name: 'prize1',
        image: giftWhite,
        rank: 1,
      },
      {
        name: 'prize2',
        image: giftWhite,
        rank: 2,
      },
      {
        name: 'prize3',
        image: giftWhite,
        rank: 3,
      },
      {
        name: 'whammy',
        image: whammy,
        rank: null,
      },
      {
        name: 'brand-logo',
        image: '',
        rank: null,
      },
    ],
    isBlinking: true,
    cells: [],
    loseModal: false,
    winModal: null,
    isDisabled: false,
  };

  blinkingAudio = new Audio(blinking);
  winningAudio = new Audio(winning);
  loseingAudio = new Audio(loseing);

  componentDidMount() {
    this.blink();
  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
  }

  getRandom = (list) => {
    const cell = list[Math.floor(Math.random() * list.length)];
    return { ...cell };
  };

  blink = () => {
    const { availableCells, isBlinking } = this.state;
    const cells = [];

    if (isBlinking) {
      this.blinkingAudio.play();
      for (let i = 0; i < 18; i++) {
        cells.push(this.getRandom(availableCells));
      }

      cells[Math.floor(Math.random() * cells.length)].active = true;
      this.setState({ cells });

      this.timerHandle = setTimeout(() => {
        this.blink();
        this.timerHandle = 0;
      }, 500);
    }
  };

  clickHandler = () => {
    const { cells } = this.state;
    const {
      availablePrizes,
      addAwardedPrizeForCampaign,
      updateGameStatus,
      activeCanpaign,
      match: {
        params: { userId, campaignId },
      },
    } = this.props;

    this.setState({ isBlinking: false, isDisabled: true });
    this.blinkingAudio.pause();
    this.blinkingAudio.currentTime = 0;
    const awardedOption = cells.find((e) => e.active);

    const newGameStatus = {
      ...activeCanpaign.gameStatuses[0],
      gameStatusType: 1,
      isPressYourLuckSpinUsed: 1,
    };

    if (awardedOption.name === 'whammy') {
      this.setState({ loseModal: true });
      this.loseingAudio.play();
      return updateGameStatus({ id: newGameStatus.id }, newGameStatus);
    }

    if (awardedOption.name === 'brand-logo') {
      const prize = availablePrizes.filter(
        (e) => e.isPressYourLuckPrize && e.rank === null
      )[0];
      if (prize) {
        this.setState({ winModal: prize });
        this.winningAudio.play();
        return addAwardedPrizeForCampaign({
          userId,
          prizeId: prize.id,
          campaignId,
        }).then(() =>
          updateGameStatus({ id: newGameStatus.id }, newGameStatus)
        );
      } else {
        this.setState({ loseModal: true });
        this.loseingAudio.play();
        return updateGameStatus({ id: newGameStatus.id }, newGameStatus);
      }
    }

    if (awardedOption.rank !== null) {
      const prize = availablePrizes.filter(
        (e) => e.isPressYourLuckPrize && e.rank === awardedOption.rank
      )[0];
      if (prize) {
        this.setState({ winModal: prize });
        this.winningAudio.play();
        return addAwardedPrizeForCampaign({
          userId,
          prizeId: prize.id,
          campaignId,
        }).then(() =>
          updateGameStatus({ id: newGameStatus.id }, newGameStatus)
        );
      } else {
        this.setState({ loseModal: true });
        this.loseingAudio.play();
        return updateGameStatus({ id: newGameStatus.id }, newGameStatus);
      }
    }
  };

  drawCell = (cell, index) => {
    const { logo } = this.props;

    return (
      <div
        key={index}
        className={`cell ${cell.name} ${cell.active ? 'active' : ''}`}>
        {cell.name === 'brand-logo' ? (
          <img
            src={
              logo?.base64String
                ? `data:${logo.mimeType};base64,${logo.base64String}`
                : logo?.url
            }
            alt='logo'
          />
        ) : (
          <img src={cell.image} alt={cell.name} />
        )}
      </div>
    );
  };

  render() {
    const { cells, loseModal, winModal, isDisabled } = this.state;
    const { logo, availablePrizes } = this.props;

    const prizes = availablePrizes
      .filter((e) => e.isPressYourLuckPrize)
      .sort((a, b) => (b.rank === null || a.rank > b.rank ? 1 : -1));

    return (
      <>
        <div className='press-your-luck-row'>
          <div className='press-your-luck'>
            <div className='board'>
              <div className='row'>{cells.slice(0, 6).map(this.drawCell)}</div>

              <div className='row'>
                <div className='column'>
                  {cells.slice(6, 9).map(this.drawCell)}
                </div>

                <div className='column middle'>
                  <div className='center'>
                    <div className='logo-container'>
                      <img
                        src={
                          logo?.base64String
                            ? `data:${logo.mimeType};base64,${logo.base64String}`
                            : logo?.url
                        }
                        alt='logo'
                      />
                    </div>
                    <button
                      onClick={this.clickHandler}
                      className='game-stop'
                      disabled={isDisabled}>
                      Press Your Luck
                    </button>
                  </div>
                </div>

                <div className='column'>
                  {cells.slice(9, 12).map(this.drawCell)}
                </div>
              </div>

              <div className='row'>
                {cells.slice(12, 18).map(this.drawCell)}
              </div>
            </div>
          </div>
          <div className='press-your-luck-prizes'>
            {prizes &&
              prizes.map((prize) => (
                <div
                  key={prize.id}
                  className={`prize  ${
                    prize.rank === null ? 'brand-logo-color' : ''
                  } ${prize.rank === 1 ? 'prize1' : ''} ${
                    prize.rank === 2 ? 'prize2' : ''
                  } ${prize.rank === 3 ? 'prize3' : ''}`}>
                  <div className='prize-content'>
                    <h3>{prize.name || ''}</h3>
                    <p>
                      {prize.fulfillmentDescription ||
                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
                    </p>
                  </div>
                  <div className='prize-image'>
                    <img src={prize.imageFile.url} alt='' />
                  </div>
                </div>
              ))}
            {loseModal && (
              <div className='lose-press-dialog'>
                <div className='lose-dialog-title'>Sorry!</div>
                <div className='lose-dialog-description'>You lose it all!</div>
                <div className='lose-dialog-buttons '>
                  <button
                    onClick={() => {
                      this.loseingAudio.pause();
                      this.props.handleLogout(true);
                    }}>
                    Log out
                  </button>
                </div>
              </div>
            )}
            {winModal && (
              <div
                className={`win-dialog ${
                  winModal.rank === null ? 'brand-logo-color' : ''
                } ${winModal.rank === 1 ? 'prize1' : ''} ${
                  winModal.rank === 2 ? 'prize2' : ''
                } ${winModal.rank === 3 ? 'prize3' : ''}`}>
                <div className='win-dialog-title'>WIN!</div>
                <div className='win-dialog-image'>
                  <img src={winModal.imageFile.url} alt='' />
                </div>

                <div className='win-dialog-buttons '>
                  <button
                    onClick={() => {
                      this.winningAudio.pause();
                      this.props.handleLogout(true);
                    }}>
                    Log out
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  activeCanpaign: getActiveCanpaign(state.games),
});

const mapDispatchToProps = { addAwardedPrizeForCampaign, updateGameStatus };

PressYourLuck = connect(mapStateToProps, mapDispatchToProps)(PressYourLuck);
PressYourLuck = withRouter(PressYourLuck);

export default PressYourLuck;
