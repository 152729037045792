import axios from 'axios';
import { requestStart, requestStop, requestClean } from 'store/global/actions';
import { enqueueSnackbar } from 'store/notifications/actions';
import store from 'store';
import { history } from '../index';
import { logout } from 'store/authentication/actions';

//axios.defaults.baseURL = `/${process.env.REACT_APP_BASE_URL}`;

const getErrorMessage = ({ response }) => {
  let message = 'Something went wrong';

  console.error(response);

  if (response?.data?.error_description) {
    return response.data.error_description;
  }

  if (response?.data?.ExceptionMessage) {
    return response.data.ExceptionMessage;
  }

  if (response?.data?.message) {
    return response.data.message;
  }

  if (response?.data?.Message) {
    return response.data.Message;
  }

  return message;
};

axios.interceptors.request.use(
  (config) => {
    const access_token =
      localStorage.getItem('ACCESS_TOKEN') ||
      sessionStorage.getItem('ACCESS_TOKEN');
    const token_type =
      localStorage.getItem('TOKEN_TYPE') ||
      sessionStorage.getItem('TOKEN_TYPE');

    if (access_token && token_type) {
      config.headers['Authorization'] = `${token_type} ${access_token}`;
    } else {
      history.push('/login');
    }
    store.dispatch(requestStart(config.url));
    return config;
  },
  (error) => {
    store.dispatch(requestClean());
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    store.dispatch(requestStop(response.config.url));
    return response;
  },
  (error) => {
    const message = getErrorMessage(error);

    if (error.response && error.response.status === 401) {
      store.dispatch(logout());
    }

    if (error.response && error.response.status === 403) {
      store.dispatch(logout());
    }

    if (error.response && error.response.status !== 403 && message) {
      store.dispatch(
        enqueueSnackbar({
          message,
          options: {
            variant: 'error',
          },
        })
      );
    }

    store.dispatch(requestClean());
    return Promise.reject(error);
  }
);
