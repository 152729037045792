import { createSelector } from 'reselect';
import moment from 'moment';

export const getGameDetails = (game) =>
  game && game.details ? { ...game.details[0] } : null;
export const getGameProgress = (game) =>
  game && game.progress ? game.progress : null;
export const getSelectedCampaign = (game) =>
  game && game.selectedCampaign ? game.selectedCampaign : null;

export const getActiveCanpaign = createSelector(
  [getGameDetails, getSelectedCampaign],
  (gameDetails, selectedCampaign) => {
    return selectedCampaign
      ? selectedCampaign
      : gameDetails && gameDetails.campaigns.length > 0
      ? gameDetails.campaigns[0]
      : null;
  }
);

export const getQuestions = createSelector(
  [getActiveCanpaign, getGameProgress],
  (activeCanpaign, gameProgress) => {
    return activeCanpaign &&
      activeCanpaign.questions.length > 0 &&
      gameProgress instanceof Array
      ? activeCanpaign.questions
          .sort((a, b) => {
            return moment(a.createdDate).diff(moment(b.createdDate));
          })
          .sort((a, b) => {
            return gameProgress.findIndex((e) => e.questionId === b.id);
          })
      : null;
  }
);

export const getQuestionIndex = createSelector(
  [getGameProgress],
  (gameProgress) => {
    return gameProgress instanceof Array ? gameProgress.length : 0;
  }
);

export const getEarnedSpins = createSelector(
  [getGameProgress],
  (gameProgress) => {
    return gameProgress instanceof Array
      ? gameProgress.filter((e) => e.isCorrect).length
      : 0;
  }
);

export const getAvailablePrizes = createSelector(
  [(state) => state.availablePrizes],
  (availablePrizes) => {
    return availablePrizes instanceof Array
      ? availablePrizes.sort((a, b) => {
          return a.rank < b.rank;
        })
      : null;
  }
);

export const getLogo = createSelector([getGameDetails], (gameDetails) => {
  return gameDetails && gameDetails.logo ? gameDetails.logo : null;
});
