import axios from 'axios';
import { api } from 'settings';
import { enqueueSnackbar } from 'store/notifications/actions';

export const CLEAR_USERS = 'CLEAR_USERS';
export const FETCH_USERS = 'FETCH_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USERS = 'DELETE_USERS';

export const clearUsers = () => (dispatch) =>
  dispatch({
    type: CLEAR_USERS,
  });

export const fetchUsers = (campaignId) => (dispatch) => {
  return axios.get(`${api.users.getAll}/${campaignId}`).then((response) => {
    dispatch({
      type: FETCH_USERS,
      payload: response.data,
    });
    return response.data;
  });
};

export const addUser =
  (data, withAvatar = false, campaignId, clientId) =>
  (dispatch) => {
    return axios
      .post(`${api.users.post}/${clientId}/${campaignId}`, data)
      .then((response) => {
        dispatch({
          type: ADD_USER,
          payload: response.data,
        });
        return response.data;
      });
  };

export const updateUser =
  (data, withAvatar = false) =>
  (dispatch) => {
    return axios
      .put(`${api.users.put}/${withAvatar}`, data)
      .then((response) => {
        dispatch({
          type: UPDATE_USER,
          payload: response.data,
        });
        return response.data;
      });
  };

export const deleteUser = (campaignId, userId) => (dispatch) => {
  return axios
    .post(`${api.users.delete}/${campaignId}/${userId}`)
    .then((response) => {
      dispatch({
        type: DELETE_USER,
        payload: response.data,
      });
      dispatch(
        enqueueSnackbar({
          message: 'The user has been deleted',
          options: {
            variant: 'success',
          },
        })
      );
      return response.data;
    });
};
export const deleteAllUsers = (campaignId) => (dispatch) => {
  return axios
    .delete(`${api.users.deleteAll}/${campaignId}`)
    .then((response) => {
      dispatch(
        enqueueSnackbar({
          message: 'All users have been deleted',
          options: {
            variant: 'success',
          },
        })
      );
      dispatch({
        type: DELETE_USERS,
      });
      return response;
    });
};

export const downloadTemplate = (campaignId) => (dispatch) => {
  return axios
    .post(`${api.users.downloadTemplate}/${campaignId}`, null, {
      headers: {
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    })
    .then((response) => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const file = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const fileURL = window.URL.createObjectURL(file);
      a.href = fileURL;
      a.download = response.headers['content-disposition'].split('=')[1];
      a.click();
      return response.data;
    });
};

export const uploadTemplate = (formData, campaignId) => (dispatch) => {
  return axios
    .post(`${api.users.uploadTemplate}/${campaignId}`, formData, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    })
    .then((response) => {
      dispatch(
        enqueueSnackbar({
          message: 'All users have been uploaded',
          options: {
            variant: 'success',
          },
        })
      );
      dispatch({
        type: FETCH_USERS,
        payload: response.data,
      });
      return response.data;
    });
};
export const sendNotificationEmail = (campaignId, userId) => (dispatch) => {
  return axios
    .post(`${api.users.sendNotificationEmail}/${campaignId}/${userId}`)
    .then((response) => {
      dispatch(
        enqueueSnackbar({
          message: 'Email has been sent',
          options: {
            variant: 'success',
          },
        })
      );
      return response.data;
    });
};
