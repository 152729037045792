import React, { useRef } from 'react';
import './UploadTemplate.scss';

const UploadTemplate = ({
  setFile,
  accept = '*.*',
  disabled = false,
  children,
}) => {
  const ref = useRef();

  const handleChange = (event) => {
    let formData = new FormData();
    let newFile = event.target.files[0];
    formData.append('', newFile);
    setFile(formData);
  };

  return (
    <div className='upload-template'>
      <label
        onClick={() => {
          if (ref) {
            ref.current.value = null;
            ref.current.click();
          }
        }}>
        {children}
      </label>
      <input
        type='file'
        id='uploadButton'
        style={{ display: 'none' }}
        onChange={handleChange}
        accept={accept}
        disabled={disabled}
        ref={ref}
      />
    </div>
  );
};

export default UploadTemplate;
